import { Typography } from '@mui/material'
import { t } from 'i18next'

export default function CvtTasksNotCompleted() {
  return (
    <>
      <Typography variant="body1">{t('viewCreditApplication.cvtTasksNotCompleted')}</Typography>
    </>
  )
}
