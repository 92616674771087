import { CustomerFeedbackDto } from '@src/data/api/feedback-api/feedback-api'

export const buildSelectedWithdrawalReasonFeedbackDto = (
  selectedReason: string,
  creditApplicationId: string,
  contactClient: boolean,
): CustomerFeedbackDto => {
  return {
    withdrawalReasonId: selectedReason,
    contactClient,
    isSatisfied: false,
    badReviewReasonId: '',
    creditApplicationId: creditApplicationId,
  }
}
