import { CircularProgress } from '@mui/material'
import { useSkipBankAccountRequestRequirements } from '@src/data/api/credit-api/credit-api'
import i18n from '@src/main'

type props = {
  creditApplicationId: string
  applicantType: string
}
export default function SkipBankAccountRequestRequirements({ creditApplicationId, applicantType }: props) {
  const [skipBankAccountRequestRequirements, isFetchingSkipBankAccountRequestRequirements] =
    useSkipBankAccountRequestRequirements(creditApplicationId)

  const filteredRequirements = skipBankAccountRequestRequirements?.filter((r) => r.applicantType === applicantType)

  return (
    <>
      {isFetchingSkipBankAccountRequestRequirements && <CircularProgress />}
      {filteredRequirements?.map((x) => <div key={x.id}>{i18n.language === 'fr' ? x.nameFr : x.nameEn}</div>)}
    </>
  )
}
