import { t } from 'i18next'
import CancelCreditAppButton from '../CancelCreditAppButton'
import { Typography } from '@mui/material'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
type props = {
  creditapp: CreditApplication
  canEdit: boolean
}
export default function CreditDisparities({ creditapp, canEdit }: props) {
  return (
    <>
      <Typography variant="body1">{t('viewCreditApplication.disparitiesMessage')}</Typography>
      <Typography variant="body1">{t('viewCreditApplication.requiredDocuments')}</Typography>
      {canEdit && <CancelCreditAppButton creditApp={creditapp} />}
    </>
  )
}
