import axios, { AxiosError } from 'axios'
import { getMerchantAuthToken } from '../../services/Authentification'

const jwtToken = getMerchantAuthToken()
let instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${jwtToken}` },
})

export function initMerchantApiClient(reportApiError: (error: AxiosError) => void) {
  const jwtToken = getMerchantAuthToken()
  instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { Authorization: `Bearer ${jwtToken}` },
  })

  instance.interceptors.response.use(
    (respons) => {
      return respons
    },
    (error: AxiosError) => {
      if (reportApiError) reportApiError(error)
      throw error
    },
  )
}

export function getApiClient() {
  return instance
}
