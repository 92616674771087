import { TextField, StandardTextFieldProps } from '@mui/material'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props extends Omit<StandardTextFieldProps, 'error'> {
  error?: FieldError
}

const InputTextField = React.forwardRef<Props, Props>(function InputTextField({ error, ...othersProps }, ref) {
  const { t } = useTranslation()
  return (
    <TextField
      {...othersProps}
      inputRef={ref}
      error={error !== undefined}
      helperText={error ? t(translateErrorCode(error)) : ' '}
      variant="outlined"
      fullWidth
      InputLabelProps={{ shrink: true }}
      autoComplete="off"
    />
  )
})

InputTextField.defaultProps = {
  error: undefined,
}

function translateErrorCode(err: FieldError): string {
  if (err.type === 'required') return 'common.errors.required'
  if (err.type === 'test-Sin-type') return 'common.errors.number'
  return err.message ?? ''
}

export default InputTextField
