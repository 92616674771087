import { Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  Info as InfoIcon,
  AccessTimeOutlined as AccessTimeOutlinedIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material/'
import { Enum, IconType } from '@src/types/Constants'
type props = {
  iconMessage?: string
  iconType?: IconType
  onClick?: () => void
}
export default function IconSection({ iconMessage, iconType, onClick }: props) {
  let backgroundColor = ''
  let icon: JSX.Element
  switch (iconType) {
    case IconType.success:
      icon = <CheckCircleOutlineIcon style={{ color: Enum.StatusTextColors.success, marginRight: '2px' }} />
      backgroundColor = Enum.StatusBackgroundColors.success
      break
    case IconType.error:
      icon = <HighlightOffIcon style={{ color: Enum.StatusTextColors.error, marginRight: '2px' }} />
      backgroundColor = Enum.StatusBackgroundColors.error
      break
    case IconType.warning:
      icon = <ErrorOutlineIcon style={{ color: Enum.StatusTextColors.warning, marginRight: '2px' }} />
      backgroundColor = Enum.StatusBackgroundColors.warning
      break
    case IconType.info:
      icon = <InfoIcon style={{ color: Enum.StatusTextColors.info, marginRight: '2px' }} />
      backgroundColor = Enum.StatusBackgroundColors.info
      break
    case IconType.waiting:
      icon = <AccessTimeOutlinedIcon style={{ color: Enum.StatusTextColors.info, marginRight: '2px' }} />
      backgroundColor = Enum.StatusBackgroundColors.info
      break
  }

  return (
    <button
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : 'default',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: !backgroundColor ? 'white' : backgroundColor,
        margin: '-8px',
        paddingTop: '20px',
        paddingBottom: '10px',
        borderRadius: '1px 1px 8px 8px',
        border: '1px',
        boxShadow: onClick && '0px 3px 5px rgba(0, 0, 0, 0.2)',
        position: 'relative',
      }}
    >
      <Typography variant="body1" fontWeight="bold" mr={1}>
        {iconMessage}
      </Typography>
      {onClick ? <HelpOutlineIcon style={{ color: Enum.StatusTextColors.warning }} /> : icon}
    </button>
  )
}
