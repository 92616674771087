import AsyncActionButton from '@src/components/AsyncActionButton'
import { ApplicantDto } from '@src/types/CreditApplicationSchema'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

type props = {
  id: string
  coapp: ApplicantDto
}
export default function AddChangeCoappButton({ id, coapp }: props) {
  const navigate = useNavigate()
  const coAppButtonText = coapp ? t('viewCreditApplication.changeCoapp') : t('viewCreditApplication.addCoapp')
  return (
    <AsyncActionButton
      fullWidth
      variant="contained"
      onClick={() => {
        navigate(`/credit/${id}/coapplicant`)
      }}
    >
      {coAppButtonText}
    </AsyncActionButton>
  )
}
