import { DocTypes, Enum, HiddenMerchantCategories, MinimumAgeRequirementByStateIso } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { RequiredDocument } from '@src/types/RequiredDocument'

export const anyDocsAwaitingApproval = (requiredDocuments: RequiredDocument[]) => {
  return requiredDocuments?.some((doc) => doc.status === Enum.DocumentStatus.AwaitingApproval)
}

export const anyDocsAwaitingUploadOrRefused = (requiredDocuments: RequiredDocument[]) => {
  return requiredDocuments?.some(
    (doc) =>
      doc.status === Enum.DocumentStatus.AwaitingDocument ||
      doc.status === Enum.DocumentStatus.Incomplete ||
      doc.status === Enum.DocumentStatus.Refused ||
      doc.status === Enum.DocumentStatus.Deleted,
  )
}

export const getCanEdit = (originSystemId: string) => {
  return originSystemId === Enum.EOriginSystemId.MerchantDashboard
}

export const getTimeStamp = () => {
  const now = new Date()

  const year = now.getUTCFullYear().toString()
  const month = (now.getUTCMonth() + 1).toString()
  const day = now.getUTCDate().toString()
  const hour = now.getUTCHours().toString()
  const minute = now.getUTCMinutes().toString()
  const second = now.getUTCSeconds().toString()

  return `${year}${month}${day}${hour}${minute}${second}`
}

export const CvtAwaitingApproval = (requiredDocuments: RequiredDocument[]) => {
  return requiredDocuments?.some(
    (doc) => doc.typeId === DocTypes.signedCvt && doc.status === Enum.DocumentStatus.AwaitingApproval,
  )
}

export const shouldDisplayInterest = (creditApp: CreditApplication) => {
  if (creditApp.originSystemId !== Enum.EOriginSystemId.MerchantDashboard) return false
  return !HiddenMerchantCategories.includes(creditApp.loanPurposeId)
}

export function findMinimumAgeRequirement(stateIso: string) {
  switch (stateIso) {
    case Enum.EProvince.quebec:
      return MinimumAgeRequirementByStateIso.quebec
    case Enum.EProvince.ontario:
      return MinimumAgeRequirementByStateIso.ontario
    case Enum.EProvince.novaScotia:
      return MinimumAgeRequirementByStateIso.novaScotia
    case Enum.EProvince.newBrunswick:
      return MinimumAgeRequirementByStateIso.newBrunswick
    case Enum.EProvince.princeEdwardIsland:
      return MinimumAgeRequirementByStateIso.princeEdwardIsland
    case Enum.EProvince.newfoundland:
      return MinimumAgeRequirementByStateIso.newfoundland
    case Enum.EProvince.britishColombia:
      return MinimumAgeRequirementByStateIso.britishColombia
    case Enum.EProvince.saskatchewan:
      return MinimumAgeRequirementByStateIso.saskatchewan
    case Enum.EProvince.alberta:
      return MinimumAgeRequirementByStateIso.alberta
    case Enum.EProvince.manitoba:
      return MinimumAgeRequirementByStateIso.manitoba
    case Enum.EProvince.yukon:
      return MinimumAgeRequirementByStateIso.yukon
    case Enum.EProvince.nunavut:
      return MinimumAgeRequirementByStateIso.nunavut
    case Enum.EProvince.northwestTerritories:
      return MinimumAgeRequirementByStateIso.northwestTerritories
    default:
      return 18
  }
}

export const availableCreditEqualApprovedAmount = (creditApp: CreditApplication) => {
  return creditApp.availableCreditAmount == creditApp.finalCreditDecision.maxAmountFinanced
}
