import { Province } from './Constants'
import * as yup from './common/yup-extended'
import yupExtInt from './common/SchemaTypes'

export const baseAddressSchema = {
  civicNumber: yup.default.string().default(''),
  street: yup.default.string().default(''),
  apartment: yup.default.string().nullable(),
  suite: yup.default.string().nullable(),
  city: yup.default.string().nullable().default(''),
  postalCode: yup.default.string().nullable().default('').IsValidCanadianPostalCode(),
  country: yup.default.string().nullable().default('Canada'),
  stateIso: yup.default.string().nullable().default(Province.notSelected).oneOf(Object.values(Province)),
  note: yup.default.string().nullable().default(''),
  years: yupExtInt.integer.positive().default(null),
  months: yupExtInt.integer.positive().default(null),
}

export const AddressSchema = yup.default.object(baseAddressSchema).when((value: any, schema: any) => {
  if (
    value &&
    (value[0]?.civicNumber ||
      value[0]?.apartment ||
      value[0]?.suite ||
      value[0]?.street ||
      value[0]?.city ||
      value[0]?.postalCode)
  ) {
    schema.fields.civicNumber = schema.fields.civicNumber.required()
    schema.fields.street = schema.fields.street.required()
    schema.fields.city = schema.fields.city.required()
    schema.fields.postalCode = schema.fields.postalCode.required()
    schema.fields.stateIso = schema.fields.stateIso.required()
    schema.fields.country = schema.fields.country.required()
    schema.fields.years = schema.fields.years.nullable().default(null)
  }
  return schema
})

export type Address = yup.default.InferType<typeof AddressSchema>
