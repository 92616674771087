import { Card, CardContent, Grid, IconButton, Stack, Typography } from '@mui/material'
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import { ApplicantJob } from '@src/types/CreditApplicationSchema'
import { FormatCurrencyNoDecimals, formatAddress } from '@src/services/Formatter'
import { t } from 'i18next'

type Props = {
  onDelete?: () => void
  onEdit?: () => void
  data: ApplicantJob
  disableEdit: boolean
}

function DisplayJobInfosComponent({ onDelete, onEdit, data, disableEdit }: Props) {
  const { address } = data

  return (
    <Card sx={{ width: '100%', mb: 1 }}>
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item xs={12} sm={11}>
            <Typography variant="subtitle1">{`${data.employerName} (${t(`jobType.${data.jobType.toString()}`)}) : ${
              data.jobTitle
            } ${FormatCurrencyNoDecimals(data.annualSalary)}`}</Typography>
            <Typography variant="body2">{formatAddress(address)}</Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Stack direction="row">
              {onDelete && (
                <IconButton id="deleteJob" aria-label="delete" onClick={onDelete} disabled={disableEdit}>
                  <DeleteIcon />
                </IconButton>
              )}
              {onEdit && (
                <IconButton id="editJob" aria-label="edit" onClick={onEdit} disabled={disableEdit}>
                  <EditIcon />
                </IconButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

DisplayJobInfosComponent.defaultProps = {
  onEdit: undefined,
  onDelete: undefined,
}
export default DisplayJobInfosComponent
