import { Typography } from '@mui/material'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { t } from 'i18next'
import CancelCreditAppButton from '../CancelCreditAppButton'
import AddChangeCoappButton from '../AddCoappButton'

type props = {
  creditApp: CreditApplication
  canEdit: boolean
}

export default function CreditDeclined({ creditApp, canEdit }: props) {
  return (
    <>
      <Typography variant="body1">{t('viewCreditApplication.clientDeclined')}</Typography>
      {canEdit && (
        <>
          <AddChangeCoappButton id={creditApp.id} coapp={creditApp.coApplicant} />
          <CancelCreditAppButton creditApp={creditApp} />
        </>
      )}
    </>
  )
}
