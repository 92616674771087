import AsyncActionButton from '@src/components/AsyncActionButton'
import { BankAccountRequestDto, useResetBankAccountRequest } from '@src/data/api/credit-api/credit-api'
import { t } from 'i18next'

type props = {
  applicantType: string
  id: string
}

export default function ResetBankAccountButton({ applicantType, id }: props) {
  const [resetBankAccount, isResetingBankAccount, isError, isSuccess] = useResetBankAccountRequest()

  const onResetBankAccount = async () => {
    const bankRequestDto = {
      applicantType: applicantType,
      creditApplicationId: id,
    } as BankAccountRequestDto
    await resetBankAccount(bankRequestDto)
  }

  return (
    <>
      <AsyncActionButton
        variant="contained"
        color="primary"
        isPending={isResetingBankAccount}
        isError={isError}
        isSuccess={isSuccess}
        onClick={onResetBankAccount}
      >
        {t('incomeVerification.resetBankAccount')}
      </AsyncActionButton>
    </>
  )
}
