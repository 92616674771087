import { Alert, AlertTitle, List, ListItemText } from '@mui/material'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

type props = {
  serverErrors: Error
}

type ServerErrors = {
  message: string
  type: string
  fieldErrors: object
}

export default function ServerErrorsAlert({ serverErrors }: props): JSX.Element {
  const { t } = useTranslation()
  const response = (serverErrors as AxiosError)?.response
  const errors = response?.data as ServerErrors

  return (
    <Alert sx={{ mb: 2 }} severity="error">
      <AlertTitle>{t('common.error')}</AlertTitle>
      {errors?.type === 'FieldValidations' && (
        <List>
          {Object.values(errors?.fieldErrors).map((error) => {
            return <ListItemText key={Object.keys(error)[0]}>{error[0]}</ListItemText>
          })}
        </List>
      )}
      {(response?.status === 400 || response?.status === 404) && !errors?.type && (
        <div> {t(`common.errors.${response.data as string}`)}</div>
      )}
      {!errors && <div> {t(`common.errors.serverError`)}</div>}
    </Alert>
  )
}
