import AddIcon from '@mui/icons-material/Add'
import { Alert, AlertTitle, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import { EContractStatus, Enum } from '@src/types/Constants'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import CreditTile from './components/CreditSection/CreditTile'
import DocumentTable from './components/DocumentSection/DocumentTable'
import PrequalTile from './components/PrequalSection/PrequalTile'
import ApplicantInfoBox from './components/applicantInfoBox'
import LoanInfoBox from './components/loanInfoBox'
import { isServerAnalysingCredit, canPollCreditApp, isServerCreatingContract } from './credit-hooks'
import ContractTile from './components/ContractSection/ContractTile'
import IncomeVerificationTile from './components/IncomeVerificationSection/IncomeVerificationTile'
import FundingTile from './components/FundingSection/FundingTile'
import PageSpinner from '../../components/PageSpinner'
import { useCreditApplicationById } from '@src/data/api/credit-api/credit-api'
import { getCanEdit } from '@src/data/creditapp-selectors'

export default function ViewCreditApplicationPage() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [shouldPoll, setShouldPoll] = useState(false)
  const [creditApplication, isLoadingCreditApp] = useCreditApplicationById(id, shouldPoll) // if true, poll every 5 secs
  const [merchant, isLoadingMerchant] = useMerchantById(creditApplication?.merchantId ?? '')

  const [error, setError] = useState(null)

  const canEdit = getCanEdit(creditApplication?.originSystemId)
  const navigate = useNavigate()

  useEffect(() => {
    if (creditApplication) {
      const should =
        (isServerAnalysingCredit(creditApplication) || isServerCreatingContract(creditApplication)) &&
        canPollCreditApp(creditApplication)
      setShouldPoll(should)
    }
  }, [creditApplication, isLoadingCreditApp])

  return (
    <>
      <PageSpinner isLoading={isLoadingMerchant || isLoadingCreditApp} withBackdrop />
      {error && (
        <>
          <Alert
            severity="error"
            sx={{
              width: '100%',
              marginBottom: '2rem',
            }}
          >
            <AlertTitle>{t('common.error')}</AlertTitle>
            {error.message}
          </Alert>
        </>
      )}
      {creditApplication && merchant && (
        <>
          <Grid container spacing={2} sx={{ wordWrap: 'break-word', mt: 0 }} columns={12}>
            {creditApplication && merchant?.name && (
              <Grid item xs={4}>
                <LoanInfoBox creditApp={creditApplication} merchant={merchant} canEdit={canEdit}></LoanInfoBox>
              </Grid>
            )}
            {creditApplication.applicant && (
              <ApplicantInfoBox
                applicant={creditApplication.applicant}
                applicantTitle={t('viewCreditApplication.applicant')}
                applicantType={Enum.EApplicantType.Applicant}
                creditApplicationId={id}
                prohibitedPhone={creditApplication?.coApplicant?.cellPhone}
                disabled={creditApplication?.contract?.status === EContractStatus.completed || !canEdit}
              />
            )}
            {creditApplication.coApplicant && (
              <ApplicantInfoBox
                applicant={creditApplication?.coApplicant}
                applicantTitle={t('viewCreditApplication.coApplicant')}
                applicantType={Enum.EApplicantType.Coapplicant}
                creditApplicationId={id}
                prohibitedPhone={creditApplication.applicant.cellPhone}
                disabled={creditApplication?.contract?.status === EContractStatus.completed || !canEdit}
              />
            )}
            {!creditApplication.coApplicant &&
              creditApplication?.contract?.status !== EContractStatus.completed &&
              canEdit && (
                <Paper
                  elevation={2}
                  component={Grid}
                  item
                  xs
                  sx={{
                    borderRadius: 2,
                    p: 1,
                    m: 1,
                  }}
                >
                  <Stack direction="column" justifyContent="center" alignItems={'center'} height="100%" spacing={1}>
                    <IconButton
                      onClick={() => {
                        navigate(`/credit/${id}/coapplicant`)
                      }}
                    >
                      <AddIcon style={{ fontSize: '3rem' }} />
                    </IconButton>
                    {t('viewCreditApplication.addCoapp')}
                  </Stack>
                </Paper>
              )}
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={12} pb={2} ml={1}>
              <Typography variant="h5" component="div">
                {t('viewCreditApplication.applicationProcessTracking')}
              </Typography>
            </Grid>
            <Stack
              direction={'row'}
              sx={{
                scrollbarWidth: 'thin',
                overflowY: 'auto',
              }}
              useFlexGap
            >
              <PrequalTile creditApp={creditApplication} />
              <CreditTile creditApp={creditApplication} merchant={merchant} canEdit={canEdit} />

              <IncomeVerificationTile
                applicant={creditApplication?.applicant}
                requiredDocuments={creditApplication?.requiredDocuments}
                creditDecision={creditApplication?.finalCreditDecision.decision}
                applicationStatus={creditApplication?.status}
                creditApplicationId={creditApplication?.id}
                merchant={merchant}
                hasCoApplicant={creditApplication.coApplicant !== null}
                canEdit={canEdit}
              />
              {creditApplication?.coApplicant && (
                <IncomeVerificationTile
                  applicant={creditApplication?.coApplicant}
                  requiredDocuments={creditApplication?.requiredDocuments}
                  creditDecision={creditApplication?.finalCreditDecision.decision}
                  applicationStatus={creditApplication?.status}
                  creditApplicationId={creditApplication?.id}
                  merchant={merchant}
                  hasCoApplicant
                  canEdit={canEdit}
                />
              )}
              <ContractTile creditApp={creditApplication} isPolling={shouldPoll} canEdit={canEdit} />
              <FundingTile creditApp={creditApplication} />
            </Stack>
          </Grid>
          <Grid container item xs={12}>
            <DocumentTable
              requiredDocuments={creditApplication.requiredDocuments}
              creditApplicationId={id}
              setError={setError}
              canEdit={canEdit}
            />
          </Grid>
        </>
      )}
    </>
  )
}
