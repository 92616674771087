import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material'
import {
  Alert,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
} from '@mui/material'
import { Merchant } from '@src/types/Merchant'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AsyncActionButton from './AsyncActionButton'
import InputTextField from './InputTextField'
import { useMerchantSelection, useMerchantsFilter } from './SelectMerchantsDialog-hooks'

type Props = {
  open: boolean
  title: string
  label: string
  merchantsTotal: Merchant[]
  merchantsSelected: Merchant[]
  onConfirm: (merchants: Merchant[]) => void
  onCancel: () => void
}

function SelectMerchantsDialog({ open, title, label, merchantsTotal, merchantsSelected, onConfirm, onCancel }: Props) {
  const { t } = useTranslation()
  const { filteredMerchants, query, setQuery } = useMerchantsFilter(merchantsTotal)
  const { selectedMerchants, setSelectedMerchants, handleItemChecked } = useMerchantSelection(merchantsSelected)

  const cancel = () => {
    setQuery('')
    setSelectedMerchants(merchantsSelected)
    onCancel()
  }

  const confirmSelection = () => {
    onConfirm(selectedMerchants)
    setQuery('')
  }

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      keepMounted
      transitionDuration={0}
      disablePortal
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 5, width: 600 }}>
          <Grid item xs={12} md={14}>
            <InputTextField id="queryField" value={query} onChange={(e) => setQuery(e.target.value)} label={label} />
          </Grid>
          <Grid item xs={12} md={14}>
            <div>
              {query && query.trim() && filteredMerchants.length === 0 && (
                <Alert variant="filled" severity="error">
                  {t('common.searchYieldsNoResults')}
                </Alert>
              )}
              {query === '' && (
                <Alert variant="filled" severity="info">
                  {t('common.typeToStartSearch')}
                </Alert>
              )}
              <List>
                {filteredMerchants.map((merchant) => (
                  <ListItem key={merchant.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedMerchants.some((m) => m.id === merchant.id)}
                          onChange={(e) => handleItemChecked(merchant, e.target.checked)}
                        />
                      }
                      label={merchant.name}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <Grid container sx={{ px: 3, py: 2, display: 'flex', flexWrap: 'wrap', maxWidth: 650 }}>
        {selectedMerchants.map((merchant: Merchant) => (
          <Chip
            key={merchant.id}
            label={merchant.name}
            size="small"
            onDelete={() => handleItemChecked(merchant, false)}
          />
        ))}
      </Grid>
      <DialogActions>
        <AsyncActionButton startIcon={<CancelIcon />} onClick={cancel}>
          {t('common.cancel')}
        </AsyncActionButton>
        <AsyncActionButton variant="contained" startIcon={<DoneIcon />} onClick={confirmSelection}>
          {t('common.confirm')}
        </AsyncActionButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(SelectMerchantsDialog)
