import { Typography } from '@mui/material'

import { t } from 'i18next'
import CancelCreditAppButton from '../CancelCreditAppButton'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
type props = {
  creditApp: CreditApplication
  canEdit: boolean
}
export default function CreditManualRevision({ creditApp, canEdit }: props) {
  return (
    <>
      <Typography variant="body1">{t('viewCreditApplication.clientManualRevision')}</Typography>
      {canEdit && <CancelCreditAppButton creditApp={creditApp} />}
    </>
  )
}
