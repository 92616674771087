import { Chip, Grid, Tooltip, Typography } from '@mui/material'
import { formatDate } from '@src/services/Formatter'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { t } from 'i18next'
import CancelCreditAppButton from './CancelCreditAppButton'
import { Enum } from '@src/types/Constants'
import InfoIcon from '@mui/icons-material/Info'

type props = {
  creditApp: CreditApplication
  merchant: Merchant
  canEdit: boolean
}
export default function LoanInfoBox({ creditApp, merchant, canEdit }: props) {
  return (
    <>
      <Grid item m={1}>
        {creditApp.originSystemId !== Enum.EOriginSystemId.MerchantDashboard && (
          <Chip
            icon={
              <Tooltip
                title={
                  creditApp.originSystemId === Enum.EOriginSystemId.Backoffice
                    ? t('viewCreditApplication.readOnlyBackOffice')
                    : t('viewCreditApplication.readOnlySubscription')
                }
              >
                <InfoIcon />
              </Tooltip>
            }
            label={t('viewCreditApplication.readOnly')}
            sx={{
              marginBottom: '0.5rem',
              backgroundColor: Enum.StatusBackgroundColors.info,
              color: Enum.StatusTextColors.info,
            }}
          />
        )}
        <Typography variant="h4" fontWeight={'bold'} color={'#08abf0'} component="div">
          {t('common.application')} #{creditApp.referenceNumber}
        </Typography>
        <Grid container item xs={12} sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              {t('viewCreditApplication.merchant')}
            </Typography>
            <Typography variant="body1">{merchant.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              {t('viewCreditApplication.createdBy')}
            </Typography>
            <Typography variant="body1">{creditApp.createdByUserFullName}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              {t('viewCreditApplication.createdOn')}
            </Typography>
            <Typography variant="body1">{formatDate(creditApp.createdOn)}</Typography>
          </Grid>
          <Grid item xs={6} mb={3}>
            <Typography variant="subtitle1" gutterBottom>
              {t('viewCreditApplication.expiresOn')}
            </Typography>
            <Typography variant="body1">{formatDate(creditApp.expiresOn)}</Typography>
          </Grid>
        </Grid>
        {canEdit && (
          <Grid item xs={4}>
            <CancelCreditAppButton creditApp={creditApp} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
