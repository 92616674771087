import { Typography } from '@mui/material'
import { t } from 'i18next'
import FundingSummaryButton from './FundingSummaryButton'
type props = {
  activationDate: string
  id: string
  financingProgramId: string
}
export default function FundingLoanCreated({ activationDate, id, financingProgramId }: props) {
  return (
    <div>
      <Typography variant="subtitle1">{t('viewCreditApplication.activationDate')}</Typography>
      <Typography variant="body1">{activationDate}</Typography>
      <br />
      <Typography variant="body1">{t('viewCreditApplication.fundingAwaitingActivationDate')}</Typography>
      <br />
      <br />
      <FundingSummaryButton id={id} financingProgramId={financingProgramId} />
    </div>
  )
}
