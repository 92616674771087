import { Divider, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SkipBankAccountRequestRequirements from './SkipBankAccountRequestRequirements'

type Props = {
  creditApplicationId: string
  applicantType: string
}

const ManualOptionBox = ({ creditApplicationId, applicantType }: Props) => {
  const { t } = useTranslation()

  return (
    <Grid
      item
      sm={5}
      border={'2px solid'}
      borderColor="#D6D7D6DD"
      padding="2rem"
      paddingTop="1rem"
      borderRadius="1rem"
      marginLeft="2rem"
    >
      <>
        <Typography variant="h5">Option 2</Typography>
        <Divider sx={{ marginBottom: '1rem' }} />
      </>
      <Typography variant="h6" marginBottom="1rem">
        {t('incomeVerification.bankAccountProcedure.optionTwo')}
      </Typography>
      <Typography marginBottom="1rem">{t('incomeVerification.bankAccountProcedure.upload')}</Typography>
      <div style={{ marginLeft: '2rem' }}>
        <SkipBankAccountRequestRequirements creditApplicationId={creditApplicationId} applicantType={applicantType} />
      </div>
    </Grid>
  )
}

export default ManualOptionBox
