import {
  FilterAltOutlined as FilterAltOutlinedIcon,
  FilterAltOffOutlined as FilterAltOffOutlinedIcon,
} from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { Button, ButtonGroup, InputAdornment, TextField, Toolbar, debounce } from '@mui/material'
import { Enum } from '@src/types/Constants'

type props = {
  handleSearch: (value: string) => void
  onOpenFilterClicked: () => void
  creditApplicationStatusFilter: string
  hasAFilter: boolean
  handleClearFilter: () => void
}

function Header({
  handleSearch,
  onOpenFilterClicked,
  creditApplicationStatusFilter,
  hasAFilter,
  handleClearFilter,
}: props) {
  const debounceOnChange = debounce(handleSearch, 1000)
  const hiddenFilters = creditApplicationStatusFilter === Enum.ECreditApplicationStatus.Draft
  return (
    <Toolbar disableGutters>
      <TextField
        sx={{ minWidth: '20%', borderRadius: '10px' }}
        onChange={(e) => debounceOnChange(e.target.value)}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          sx: { borderRadius: '1rem' },
        }}
      />
      {!hiddenFilters && (
        <ButtonGroup variant="text" disableElevation>
          <Button size="large" onClick={onOpenFilterClicked}>
            <FilterAltOutlinedIcon fontSize="large" color={!hasAFilter ? 'inherit' : 'warning'} />
          </Button>
          <Button size="large" onClick={handleClearFilter} disabled={!hasAFilter}>
            <FilterAltOffOutlinedIcon fontSize="medium" />
          </Button>
        </ButtonGroup>
      )}
    </Toolbar>
  )
}

export default Header
