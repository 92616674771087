import { CircularProgress, IconButton, Stack } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useUploadRequiredDocument } from '@src/data/api/credit-api/credit-api'
import { Dispatch, useCallback } from 'react'
import { Constants, Enum } from '@src/types/Constants'
import { t } from 'i18next'
import AsyncActionButton from '@src/components/AsyncActionButton'

type Props = {
  applicantType: string
  typeId: number
  subKey?: string | undefined
  creditApplicationId: string
  status: string
  setError: Dispatch<any>
}

export default function UploadFileButton({
  applicantType,
  creditApplicationId,
  typeId,
  subKey,
  status,
  setError,
}: Props) {
  const MAX_FILE_SIZE = 10485760 // 10MB
  const [uploadDocument, isPending, isError] = useUploadRequiredDocument()

  const getUploadButton = () => {
    const uploadSpinner = (
      <IconButton>
        <CircularProgress />
      </IconButton>
    )
    const uploadArrowIcon = (
      <AsyncActionButton
        fullWidth={false}
        variant="contained"
        color="primary"
        component="label"
        isError={isError}
        sx={{ marginLeft: 0, minWidth: 'auto' }}
      >
        <input
          type="file"
          hidden
          accept={Constants.SupportedDocumentTypes}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(event)}
        />
        <UploadFileIcon fontSize="small" />
      </AsyncActionButton>
    )

    if (isPending) {
      return uploadSpinner
    }

    if (status === Enum.DocumentStatus.Incomplete || !isSubmited(status)) return uploadArrowIcon
  }

  const isSubmited = (status: string) =>
    status === Enum.DocumentStatus.Approved || status === Enum.DocumentStatus.AwaitingApproval

  const onChangeHandler = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target
      if (files) {
        const filesArray = Array.from(files)
        let hasError = false
        filesArray.forEach((file) => {
          if (file.size > MAX_FILE_SIZE) {
            setError(new Error(t(`common.errors.fileSizeTooBig`)))
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
            hasError = true
          }
        })
        if (!hasError) {
          await uploadDocument({
            creditApplicationId: creditApplicationId,
            applicantType: applicantType,
            typeId: typeId,
            subKey: subKey,
            files: filesArray,
          })
        }
      }
    },
    [applicantType, creditApplicationId, setError, subKey, typeId, uploadDocument],
  )

  return (
    <Stack direction="row" marginTop="-0.5rem" justifyContent="center">
      {getUploadButton()}
    </Stack>
  )
}
