import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { SearchMerchantFilters } from '../../../types/SearchMerchantFilters'
import { Merchant } from '../../../types/Merchant'
import { getApiClient } from '../api-client'

const SCOPE = 'merchants'
const LIST = 'list'
const DETAIL = 'detail'
const SCOPEMERCHANTS = 'merchants'
const SCOPEBANNERS = 'banners'

const oneHour = 3600000

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allLists: () => [{ scope: SCOPE, entity: LIST }] as const,
  list: (filters: SearchMerchantFilters) => [{ scope: SCOPE, entity: LIST, ...filters }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  bannerMerchants: (id: string) => [{ scope: SCOPEBANNERS, entity: SCOPEMERCHANTS, id }] as const,
}

const getMerchant = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<Merchant>(`/Merchant/${encodeURIComponent(id)}`)
  return response.data
}

export function useMerchantById(merchantId: string): [Merchant | null, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.detail(merchantId),
    queryFn: getMerchant,
    enabled: !!merchantId,
    staleTime: oneHour,
    gcTime: oneHour,
  })

  return [data ?? null, isFetching]
}

const getMerchantsByParentId = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['bannerMerchants']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.post(`/Merchant/${id}/Subsidiaries`)
  return response.data as Merchant[]
}

export function useMerchantByParentId(id: string): [Merchant[], boolean] {
  const { data, isFetching } = useQuery({
    queryKey: keysFactory.bannerMerchants(id),
    queryFn: getMerchantsByParentId,
    placeholderData: [],
    enabled: !!id,
    staleTime: oneHour,
    gcTime: oneHour,
  })

  return [data, isFetching]
}
