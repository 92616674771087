import { Typography } from '@mui/material'
import { shouldDisplayInterest } from '@src/data/creditapp-selectors'
import { FormatCurrency } from '@src/services/Formatter'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { t } from 'i18next'
import { usePaymentPlanList } from '../../worksheet-hooks'
import { Merchant } from '@src/types/Merchant'
import { Enum } from '@src/types/Constants'

type props = {
  creditApp: CreditApplication
  merchant: Merchant
}

export default function CreditApproved({ creditApp, merchant }: props) {
  const merchantPayments = usePaymentPlanList(creditApp, merchant)

  const getInterestRateOrPromo = () => {
    if (
      creditApp.worksheet?.finalDecision?.decision === Enum.EDecision.accept &&
      creditApp.worksheet?.paymentPlanId !== null
    ) {
      const paymentPlan = merchantPayments.find((plan) => plan.id === creditApp.worksheet?.paymentPlanId)
      if (paymentPlan) {
        return paymentPlan.label
      }
    }

    return creditApp.finalCreditDecision.interestRate + '%'
  }

  const mustDisplayInterests = shouldDisplayInterest(creditApp)
  return (
    <>
      {mustDisplayInterests && (
        <div>
          <Typography variant="subtitle1">{t('viewCreditApplication.interestRate')}</Typography>
          <Typography variant="body1">{getInterestRateOrPromo()}</Typography>
        </div>
      )}

      <div>
        <Typography variant="subtitle1">{t('viewCreditApplication.maxMontlyPayment')}</Typography>
        <Typography variant="body1">{FormatCurrency(creditApp.finalCreditDecision.maxPmtAmount)}</Typography>
      </div>

      <div>
        <Typography variant="subtitle1">{t('viewCreditApplication.amountGranted')}</Typography>
        <Typography variant="body1">{FormatCurrency(creditApp.finalCreditDecision.maxAmountFinanced)}</Typography>
      </div>
    </>
  )
}
