import { DraftCreditApplicationDto } from '@src/types/CreditApplicationSchema'
import CreditApplicationForm from './components/CreditApplicationForm'
import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import { useUser } from '@src/contexts/AppContext'
import { useDraftCreditApplicationById, useEditCreditApplicationDraft } from '@src/data/api/credit-api/credit-api'
import { useNavigate, useParams } from 'react-router-dom'

export default function EditCreditApplicationPage(): JSX.Element {
  const user = useUser()
  const [merchant] = useMerchantById(user?.merchantId ?? '')
  const navigate = useNavigate()
  const { id } = useParams()
  const [creditApplication, creditApplicationIsPending] = useDraftCreditApplicationById(id ?? '')
  const [editCreditAppMutation, isPending, isError, reset, error] = useEditCreditApplicationDraft()
  const onSave = async (data: Partial<DraftCreditApplicationDto>) => {
    data.id = id
    await editCreditAppMutation(data).then(() => {
      reset()
      navigate('/')
    })
  }

  return (
    <div>
      {!creditApplicationIsPending && creditApplication && (
        <CreditApplicationForm
          merchant={merchant}
          creditApplication={creditApplication}
          onSave={onSave}
          isPending={isPending}
          isError={isError}
          serverErrors={error}
        />
      )}
    </div>
  )
}
