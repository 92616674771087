import { forwardRef, createContext, useCallback, useContext, useMemo, useState } from 'react'
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom'
import { LinkProps, createTheme } from '@mui/material'
import { blue } from '@mui/material/colors'
import { Theme } from '@emotion/react'
import { enUS, frFR } from '@mui/x-data-grid/locales'

// eslint-disable-next-line react/display-name
const LinkBehavior = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (MUI) -> to (react-router)
    return <Link ref={ref} to={href} {...other} />
  },
)

export type TThemeStore = {
  themeName: string
  theme: Theme
  toggleTheme: () => void
}

export const ThemeContext = createContext<TThemeStore | null>(null)

export function useThemeStore() {
  const PREFERS_DARK_SETTING = 'darkMode'
  const defaultThemeName = (localStorage.getItem(PREFERS_DARK_SETTING) as 'dark' | 'light') ?? 'light'
  const [themeName, setTheme] = useState<'light' | 'dark'>(defaultThemeName)

  const toggleTheme = useCallback(() => {
    setTheme((prevMode) => {
      const newThemeName = prevMode === 'light' ? 'dark' : 'light'
      localStorage.setItem(PREFERS_DARK_SETTING, newThemeName)
      return newThemeName
    })
  }, [setTheme])

  const theme = useMemo(
    () =>
      createTheme(
        {
          components: {
            MuiLink: {
              defaultProps: {
                component: LinkBehavior,
              } as LinkProps,
            },
            MuiButtonBase: {
              defaultProps: {
                LinkComponent: LinkBehavior,
              },
            },
            MuiInputBase: {
              defaultProps: {
                // Needed to prevent adding a global style for every input field
                disableInjectingGlobalStyles: true,
              },
            },
          },
          palette: {
            primary: { main: themeName === 'dark' ? blue[200] : blue[700] },
            background: {
              default: themeName === 'dark' ? '#1e1e1e' : '#EEEEEE',
            },
            mode: themeName,
          },
          typography: {
            fontFamily: ['Inter', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
            h1: {
              fontFamily: 'Quicksand',
              fontWeight: 'bold',
            },
            h2: {
              fontFamily: 'Quicksand',
              fontWeight: 'bold',
            },
            h3: {
              fontFamily: 'Quicksand',
              fontWeight: 'bold',
            },
            h4: {
              fontFamily: 'Quicksand',
              fontWeight: 'bold',
            },
            h5: {
              fontFamily: 'Quicksand',
              fontWeight: 'bold',
            },
            h6: {
              fontFamily: 'Quicksand',
              fontWeight: 'bold',
            },
            subtitle1: {
              fontWeight: 'bold',
            },
            subtitle2: {
              fontWeight: 'bold',
            },
          },
        },
        frFR,
        enUS,
      ),
    [themeName],
  )

  return useMemo(
    () => ({
      toggleTheme,
      themeName,
      theme,
    }),
    [theme, themeName, toggleTheme],
  )
}

export function useTheme(): TThemeStore {
  const ctx = useContext(ThemeContext)
  return ctx
}
