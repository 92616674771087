import PageSpinner from '@src/components/PageSpinner'
import { useUser } from '@src/contexts/AppContext'
import { useCreditApplicationById } from '@src/data/api/credit-api/credit-api'
import { useMerchantById, useMerchantByParentId } from '@src/data/api/merchants-api/merchants-api'
import { useGetHolidaysForYear } from '@src/data/api/worksheet-api/worksheet-api'
import {} from '@src/types/CreditApplicationSchema'
import { useParams } from 'react-router-dom'
import { getMerchantList, usePaymentPlanList } from '../ViewCreditApplicationPage/worksheet-hooks'
import { EditWorksheet } from './components/EditWorksheet'
import { EditPersonalLoanWorksheetDtoSchema } from '@src/types/WorksheetSchema'

export function CreateWorksheetPage() {
  const { id } = useParams()
  const user = useUser()
  const [creditApplication, isLoadingCreditApp] = useCreditApplicationById(id, false) // if true, poll every 5 secs
  const [merchant, isLoadingMerchant] = useMerchantById(creditApplication?.merchantId ?? '')
  const [merchantsListFromParentId, isFetchingMerchantsList] = useMerchantByParentId(user.merchantId)
  const { merchantList, setMerchantList } = getMerchantList(
    creditApplication,
    merchantsListFromParentId,
    merchant,
    user,
  )
  const [listHolidays, isLoadingHolidays] = useGetHolidaysForYear(new Date().getFullYear())
  const merchantPayments = usePaymentPlanList(creditApplication, merchant)
  const isLoadingInfo = isLoadingCreditApp || isLoadingMerchant || isFetchingMerchantsList || isLoadingHolidays
  const defaultValues = creditApplication?.worksheet
    ? creditApplication.worksheet
    : EditPersonalLoanWorksheetDtoSchema.getDefault()

  return (
    <div>
      <PageSpinner withBackdrop isLoading={isLoadingInfo} />

      {!isLoadingInfo && creditApplication && merchant && (
        <EditWorksheet
          listHolidays={listHolidays}
          merchant={merchant}
          creditApplication={creditApplication}
          user={user}
          merchantsListFromParentId={merchantsListFromParentId}
          merchantPayments={merchantPayments}
          merchantList={merchantList}
          setMerchantList={setMerchantList}
          defaultValues={defaultValues}
        />
      )}
    </div>
  )
}
