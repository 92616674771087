import { Add as AddIcon } from '@mui/icons-material/'
import { Button, Paper, Stack, Tab, TableContainer, Tabs, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid'
import { useUser } from '@src/contexts/AppContext'
import { CreditDashboardFilters, useCreditApplicationList } from '@src/data/api/credit-api/credit-api'
import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import { getNumberFromQueryString } from '@src/services/query-string'
import { Enum, HiddenMerchantCategories } from '@src/types/Constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useCreditDashboardEntriesColumns, useCreditDashboardEntriesQuery } from './browseCreditApplication-hooks'
import CreditEntriesFiltersDialog from './components/CreditEntriesFiltersDialog'
import Header from './components/header'
import Footer from './components/footer'
import { frFR, enUS } from '@mui/x-data-grid/locales'
import { getEndDate, getStartDate } from './browseCreditApplicationPage-selector'

export default function BrowseCreditApplicationPage() {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const updateSearch = new URLSearchParams(location.search)
  const user = useUser()
  const theme = useTheme()

  const query = useCreditDashboardEntriesQuery(searchParams)

  const [creditApplications, isLoadingCreditApps] = useCreditApplicationList(query)
  const [merchant] = useMerchantById(user?.merchantId ?? '')

  const merchantServiceCategory = merchant?.serviceCategory
  const isVeterinary = merchantServiceCategory === Enum.ServiceCategory.Veterinary
  const isHidden = HiddenMerchantCategories.includes(merchantServiceCategory)

  // update search
  const [openFilter, setOpenFilter] = React.useState(false)

  const updateUriQuery = () => {
    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: true })
  }

  const handleSearch = (value: string) => {
    updateSearch.set('searchValue', encodeURIComponent(value))
    updateUriQuery()
  }

  const handleTabChange = (status: string) => {
    updateSearch.set('creditApplicationStatus', encodeURIComponent(status))
    updateUriQuery()
  }

  const handleFilters = (filters: CreditDashboardFilters) => {
    updateSearch.set('merchantId', encodeURIComponent(filters.merchantId))
    updateSearch.set('creditApplicationStatus', encodeURIComponent(filters.creditApplicationStatus))
    updateSearch.set('currentCustomerStep', encodeURIComponent(filters.currentCustomerStep))
    updateSearch.set('requiresMerchantAttention', encodeURIComponent(filters.requiresMerchantAttention))
    updateSearch.set('startDate', encodeURIComponent(filters.startDate))
    updateSearch.set('endDate', encodeURIComponent(filters.endDate))
    updateSearch.set('originSystemId', encodeURIComponent(filters.originSystemId))
    updateSearch.set('createdByUserId', encodeURIComponent(filters.createdByUserId))

    updateUriQuery()
    setOpenFilter(false)
  }

  const setPagination = (pagination: GridPaginationModel) => {
    updateSearch.set('offset', encodeURIComponent(query.limit * pagination.page))
    updateSearch.set('limit', encodeURIComponent(pagination.pageSize))
    updateUriQuery()
  }

  const pagination = {
    pageSize: getNumberFromQueryString(searchParams, 'limit', 25),
    page: getNumberFromQueryString(searchParams, 'offset', 0) / getNumberFromQueryString(searchParams, 'limit', 25),
  }

  // Table def
  const columns: GridColDef[] = useCreditDashboardEntriesColumns(isVeterinary, merchant?.canSkipFlinks)

  const localeTextDatagrid =
    i18n.language === 'fr'
      ? frFR.components.MuiDataGrid.defaultProps.localeText
      : enUS.components.MuiDataGrid.defaultProps.localeText

  const handleClearFilter = () => {
    handleFilters({
      merchantId: '',
      creditApplicationStatus: Enum.ECreditApplicationStatus.Active,
      currentCustomerStep: '',
      startDate: getStartDate(),
      endDate: getEndDate(),
      originSystemId: '',
      createdByUserId: '',
    } as CreditDashboardFilters)
  }

  const hasAFilter = (): boolean => {
    return (
      ![Enum.ECreditApplicationStatus.Active, Enum.ECreditApplicationStatus.Draft].includes(
        query.creditApplicationStatus,
      ) ||
      query.merchantId !== '' ||
      query.currentCustomerStep !== '' ||
      query.requiresMerchantAttention ||
      query.startDate !== getStartDate() ||
      query.endDate !== getEndDate() ||
      query.originSystemId !== '' ||
      query.createdByUserId !== ''
    )
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: '1rem', borderRadius: '8px' }}>
        <Stack sx={{ mx: '15px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            <Header
              handleSearch={handleSearch}
              onOpenFilterClicked={() => setOpenFilter(true)}
              creditApplicationStatusFilter={query.creditApplicationStatus}
              handleClearFilter={handleClearFilter}
              hasAFilter={hasAFilter()}
            />
            <Button
              variant="contained"
              sx={{ borderRadius: '1rem' }}
              startIcon={<AddIcon />}
              component={Link}
              to="/credit/add"
            >
              {t('common.newApplication')}
            </Button>
          </Box>
          <Tabs
            value={
              query.creditApplicationStatus === Enum.ECreditApplicationStatus.Active ||
              query.creditApplicationStatus === Enum.ECreditApplicationStatus.Draft
                ? query.creditApplicationStatus
                : false
            }
            onChange={(e, newStatus) => handleTabChange(newStatus)}
          >
            <Tab
              sx={{ borderBottom: '1px solid #d5d6d7', fontWeight: 'bold' }}
              label={t('common.applications')}
              value={Enum.ECreditApplicationStatus.Active}
            />
            <Tab
              sx={{ borderBottom: '1px solid #d5d6d7', fontWeight: 'bold' }}
              label={t('common.drafts')}
              value={Enum.ECreditApplicationStatus.Draft}
            />
          </Tabs>
        </Stack>
        <DataGrid
          sx={{
            height: '48rem',
            marginTop: '1rem',
            '.MuiDataGrid-columnHeaders div:first-of-type': {
              bgcolor: '#14233A',
              color: 'white',
              fontWeight: 'bold',
            },
            '.MuiDataGrid-cell': {
              color: theme.palette.mode === 'dark' ? 'white' : '#14233A',
              fontWeight: 'bold',
            },
            '.MuiDataGrid-sortIcon': { color: 'white' },
          }}
          rows={creditApplications}
          columns={columns}
          columnVisibilityModel={{
            interestRate: !isHidden,
            merchantName: user?.isBannerUser ?? false,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: query.limit,
              },
            },
          }}
          onPaginationModelChange={(model: GridPaginationModel) => setPagination(model)}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
          rowCount={creditApplications.length}
          loading={isLoadingCreditApps}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          localeText={localeTextDatagrid}
          slots={{
            footer: () => (
              <Footer
                disabledPreview={query.offset === 0}
                disabledNext={creditApplications.length < query.limit}
                pagination={pagination}
                setPagination={setPagination}
              />
            ),
          }}
        />
      </TableContainer>
      <CreditEntriesFiltersDialog
        open={openFilter}
        merchant={merchant}
        filterValues={query}
        onApplyFilters={handleFilters}
        onCancel={() => setOpenFilter(false)}
        handleClearFilter={handleClearFilter}
      />
    </>
  )
}
