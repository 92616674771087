import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { DatePicker } from '@src/components/DatePicker'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import SelectMerchantsDialog from '@src/components/SelectMerchantsDialog'
import {
  cantSelectDate,
  createNewPersonalLoanWorksheetDto,
  getPersonalFundingDto,
  isVariableInterest,
  provinceSupportsInsurance,
  updatePersonalLoanWorksheet,
} from '@src/data/worksheet-selectors'
import { FormatCurrency, formatDate, normalizeNumber } from '@src/services/Formatter'
import { EPaymentPlan, Province } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { UserDtoFromJwt } from '@src/types/User'
import { EditPersonalLoanWorksheetDto, buildEditPersonalLoanWorksheetDtoSchema } from '@src/types/WorksheetSchema'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { getPossibleTerms, useComputeFirstPaymentDateOptions } from '../../ViewCreditApplicationPage/worksheet-hooks'
import WorksheetFundingSummary from './WorksheetFundingSummary'
import InfoIcon from '@mui/icons-material/Info'
import { useGetPersonalFunding, usePostWorksheet, usePutWorksheet } from '@src/data/api/worksheet-api/worksheet-api'

type props = {
  listHolidays: Date[]
  merchant: Merchant
  creditApplication: CreditApplication
  user: UserDtoFromJwt
  merchantsListFromParentId: Merchant[]
  merchantPayments: { label: string; value: string; id: string }[]
  merchantList: Merchant[]
  setMerchantList: (newMerchants: Merchant[]) => void
  defaultValues: EditPersonalLoanWorksheetDto
}
export function EditWorksheet({
  listHolidays,
  merchant,
  creditApplication,
  user,
  merchantsListFromParentId,
  merchantPayments,
  merchantList,
  setMerchantList,
  defaultValues,
}: props) {
  const {
    control,
    register,
    trigger,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EditPersonalLoanWorksheetDto>({
    mode: 'onBlur',
    defaultValues: defaultValues,
    resolver: yupResolver(
      buildEditPersonalLoanWorksheetDtoSchema(
        creditApplication?.expiresOn,
        creditApplication?.finalCreditDecision?.maxAmountFinanced,
        listHolidays,
      ),
    ),
  })
  const [postWorksheet, isPosting, isPostError, postError] = usePostWorksheet()
  const [putWorksheet, isPuting, isPutError, updateError] = usePutWorksheet()
  const [openUserDialog, setOpenUserDialog] = React.useState<boolean>(false)
  const [openInsuranceInfoBox, setOpenInsuranceInfoBox] = useState(false)
  const deliveryOn = watch('deliveryOn')
  const amountRequested = normalizeNumber(watch('amountRequested')?.toString()) ?? 0
  const paymentFrequency = watch('paymentFrequency') ?? 'monthly'
  const term = watch('term')
  const firstPaymentOn = watch('firstPaymentOn')
  const paymentPlanId = watch('paymentPlanId')
  const includeInsurance = watch('includeInsurance')
  const possibleTerms = getPossibleTerms(creditApplication?.finalCreditDecision?.maxTermDuration ?? 0)
  const firstPaymentDateOptions = useComputeFirstPaymentDateOptions(deliveryOn, listHolidays)
  const hasVariableInterest = isVariableInterest(paymentPlanId)
  const doesProvinceSupportInsurance = provinceSupportsInsurance(creditApplication?.applicant.stateIso)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const dto = useMemo(() => {
    return getPersonalFundingDto(
      amountRequested,
      paymentFrequency,
      term,
      deliveryOn,
      new Date(firstPaymentOn),
      includeInsurance,
      creditApplication?.applicant.stateIso,
      paymentPlanId,
      creditApplication?.finalCreditDecision.interestRate,
    )
  }, [
    amountRequested,
    creditApplication?.applicant.stateIso,
    creditApplication?.finalCreditDecision.interestRate,
    deliveryOn,
    firstPaymentOn,
    includeInsurance,
    paymentFrequency,
    paymentPlanId,
    term,
  ])
  const [computedPersonalLoanFunding, isComputing] = useGetPersonalFunding(creditApplication?.id, dto)

  const onClosedMerchantDialog = useCallback(() => {
    setOpenUserDialog(false)
  }, [])

  const resetPayments = useCallback(() => {
    merchantList.forEach((merchant, index) => {
      setValue(`merchantPayments.${index}.amount`, null)
    })
  }, [merchantList, setValue])

  const onMerchantsSelected = useCallback(
    (newMerchants: Merchant[]) => {
      setOpenUserDialog(false)
      resetPayments()
      setMerchantList(newMerchants)
    },
    [resetPayments, setMerchantList],
  )

  const shouldDisableDate = useCallback(
    (date: Date) => {
      return cantSelectDate(date, listHolidays)
    },
    [listHolidays],
  )

  const onSubmit = async (formData: EditPersonalLoanWorksheetDto) => {
    merchantList.forEach((merch, index) => {
      formData.merchantPayments[index].merchantId = merch.id
      formData.merchantPayments[index].paymentMethod = merchant.defaultPaymentMethod
    })
    if (creditApplication.worksheet == null) {
      await postWorksheet({ worksheet: createNewPersonalLoanWorksheetDto(creditApplication.id, formData) }).then(() => {
        navigate(`../credit/${creditApplication.id}/view`)
      })
    } else {
      await putWorksheet({ worksheet: updatePersonalLoanWorksheet(formData, creditApplication) }).then(() => {
        navigate(`../credit/${creditApplication.id}/view`)
      })
    }
  }

  useEffect(() => {
    if (!user.isBannerUser && amountRequested) setValue(`merchantPayments.0.amount`, amountRequested)
  }, [amountRequested, setValue, user.isBannerUser])

  useEffect(() => {
    if (merchant?.paymentPlans) {
      const term = merchant.paymentPlans.find((plan) => plan.id === paymentPlanId)
      if (term) {
        setValue('term', term.loanTerm)
      }
    }
  }, [merchant.paymentPlans, paymentPlanId, setValue])

  const isAPRTooHigh = useMemo(() => {
    const address = creditApplication?.applicant.currentAddress
    if (!address || !computedPersonalLoanFunding) return false

    const { effectiveRate } = computedPersonalLoanFunding
    const { stateIso } = address

    const aprThresholds: Record<string, number> = {
      [Province.britishColombia]: 32,
      [Province.newfoundland]: 27,
    }

    const threshold = aprThresholds[stateIso]

    return threshold !== undefined && effectiveRate >= threshold
  }, [computedPersonalLoanFunding, creditApplication?.applicant.currentAddress])

  useEffect(() => {
    const firstDate = formatDate(firstPaymentDateOptions?.[0]?.toISOString())
    setValue('firstPaymentOn', firstDate)
  }, [firstPaymentDateOptions, setValue])

  return (
    <div>
      {(postError || updateError) && (
        <Paper>
          <Alert severity="error">
            <Typography>{postError?.response?.data?.message || updateError?.response?.data?.message}</Typography>
          </Alert>
        </Paper>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Grid container marginTop={5}>
            <Grid container marginBottom={5}>
              <Grid item xs={8}>
                <Typography variant="h4">{t('worksheet.worksheet')}</Typography>
                <Typography variant="h5">
                  {t('worksheet.maxLoanAmount')}:{' '}
                  {FormatCurrency(creditApplication.finalCreditDecision.maxAmountFinanced)}{' '}
                </Typography>
              </Grid>
              <Grid
                container
                sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}
                spacing={2}
              >
                <Grid item xs={1}>
                  <AsyncActionButton fullWidth={false} variant="contained" onClick={() => navigate(-1)}>
                    {t('common.previous')}
                  </AsyncActionButton>
                </Grid>
                <Grid item xs={2}>
                  <AsyncActionButton
                    fullWidth={false}
                    variant="contained"
                    disabled={isAPRTooHigh || isComputing || !computedPersonalLoanFunding}
                    isPending={isPosting || isPuting}
                    isError={isPostError || isPutError}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('worksheet.createContract')}
                  </AsyncActionButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <InputTextField
                    label={t('worksheet.requestedAmount')}
                    fullWidth
                    {...register('amountRequested')}
                    error={errors.amountRequested}
                    defaultValue={creditApplication?.worksheet?.amountRequested}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <DatePicker
                    name="deliveryOn"
                    control={control}
                    label={t('worksheet.activationDate')}
                    error={errors?.deliveryOn}
                    disablePast
                    minDate={new Date()}
                    shouldDisableDate={shouldDisableDate}
                    maxDate={creditApplication?.expiresOn}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <SelectComponent
                    disabled={hasVariableInterest}
                    valueList={possibleTerms?.map((item) => ({
                      label: `${item} ${t('worksheet.month')}`,
                      value: item,
                    }))}
                    label={t('worksheet.loanTerm')}
                    {...register('term')}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <SelectComponent
                    valueList={[{ label: 'worksheet.monthly', value: 'monthly' }]}
                    label={t('worksheet.paymentFrequency')}
                    {...register('paymentFrequency')}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <SelectComponent
                    valueList={firstPaymentDateOptions?.map((item) => ({
                      label: formatDate(item),
                      value: formatDate(item),
                    }))}
                    label={t('worksheet.firstPaymentOn')}
                    {...register('firstPaymentOn')}
                    error={errors?.firstPaymentOn}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <SelectComponent
                    valueList={merchantPayments?.map((item) => ({
                      label: item.label.includes(EPaymentPlan.regularDailyInterests)
                        ? t(`ePaymentPlan.${item.label}`)
                        : item.label,
                      value: item.value,
                    }))}
                    label={t('worksheet.paymentPlan')}
                    {...register('paymentPlanId')}
                    error={errors?.paymentPlanId}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <FormControlLabel
                    {...register('includeInsurance')}
                    control={<Checkbox defaultChecked={creditApplication?.worksheet?.includeInsurance} />}
                    label={t('worksheet.addInsurance')}
                    disabled={!doesProvinceSupportInsurance}
                  />
                  <Tooltip title={t('worksheet.includeInsurance')}>
                    <IconButton
                      onClick={() => setOpenInsuranceInfoBox((prev) => !prev)}
                      style={{ marginLeft: '-15px' }}
                    >
                      <InfoIcon style={{ fontSize: 'medium' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {openInsuranceInfoBox && (
                  <Box component="div" sx={{ padding: '10px', marginLeft: '15px' }}>
                    <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                      {t('worksheet.insuranceIncitative')}
                    </Typography>
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>
                        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                          {t('worksheet.lifeInsurance')}
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                          {t('worksheet.accidentalDisability')}
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                )}
                <Grid item xs={12}>
                  <Typography variant="h5">{t('worksheet.paymentBreakdown')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    width="100%"
                    sx={{
                      border: 1,
                      padding: 2,
                      marginTop: -2,
                      borderRadius: 1,
                    }}
                  >
                    {user.isBannerUser && (
                      <Grid container marginBottom={4} justifyContent="flex-end" display="flex" gap="0.5rem">
                        <AsyncActionButton
                          fullWidth={false}
                          color="secondary"
                          variant="contained"
                          onClick={() => setOpenUserDialog(true)}
                        >
                          {t('worksheet.addMerchantPayment')}
                        </AsyncActionButton>
                      </Grid>
                    )}

                    {merchantList &&
                      merchantList.map((merchant, index) => {
                        return (
                          <Stack key={merchant.id} direction="row" justifyContent="space-between" spacing={2}>
                            <Typography sx={{ width: '100%', marginTop: '1rem' }}>{merchant.name}</Typography>
                            <InputTextField
                              disabled={!user.isBannerUser}
                              {...register(`merchantPayments.${index}.amount`)}
                              sx={{ width: '30rem' }}
                              label={t('worksheet.paymentAmount')}
                              InputProps={{
                                endAdornment: '$',
                              }}
                              onBlur={() => trigger('amountRequested')}
                            />
                            <div style={{ marginTop: '1%' }}></div>
                          </Stack>
                        )
                      })}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  border: 1,
                  padding: 2,
                  marginLeft: 5,
                  paddingBottom: 10,
                }}
              >
                <WorksheetFundingSummary
                  creditApplication={creditApplication}
                  amountRequested={amountRequested}
                  hasVariableInterest={hasVariableInterest}
                  firstPaymentOn={firstPaymentOn}
                  merchant={merchant}
                  paymentPlanId={paymentPlanId}
                  provinceSupportsInsurance={doesProvinceSupportInsurance}
                  computedPersonalLoanFunding={computedPersonalLoanFunding}
                  isComputing={isComputing}
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </form>
      {user.isBannerUser && (
        <SelectMerchantsDialog
          open={openUserDialog}
          title={t('worksheet.addMerchantPayment')}
          label={t('common.merchant')}
          merchantsTotal={merchantsListFromParentId}
          merchantsSelected={merchantList}
          onConfirm={onMerchantsSelected}
          onCancel={onClosedMerchantDialog}
        />
      )}
    </div>
  )
}
