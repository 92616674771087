import { CreditApplication } from '@src/types/CreditApplicationSchema'
import StepStatusSection from '../StepStatusSection'
import IncomeNotCompleted from './IncomeNotCompleted'
import { GetContractIconAndMessage } from '../../viewCreditApp-selectors'
import { availableCreditEqualApprovedAmount } from '@src/data/creditapp-selectors'
import { t } from 'i18next'
import { EContractStatus, EWorksheetStatus, Enum } from '@src/types/Constants'
import ContractCompleted from './ContractCompleted'
import ContractAwaitingSignature from './ContractAwaitingSignature'
import { CircularProgress, Typography } from '@mui/material'
import CreateContractButton from './CreateContractButton'
import CvtTasksNotCompleted from './CvtTasksNotCompleted'

type props = {
  creditApp: CreditApplication
  isPolling: boolean
  canEdit: boolean
}

export default function ContractTile({ creditApp, isPolling, canEdit }: props) {
  const icon = GetContractIconAndMessage(creditApp, creditApp.worksheet)

  if (!creditApp.allIncomesConfirmed) {
    return (
      <StepStatusSection title={t('viewCreditApplication.contractSectionTitle')}>
        <IncomeNotCompleted />
      </StepStatusSection>
    )
  }
  if (creditApp.hasTasksToDoForCvt) {
    return (
      <StepStatusSection
        title={t('viewCreditApplication.contractSectionTitle')}
        iconMessage={icon.iconMessage ? t(`creditApplicationStatus.${icon.iconMessage}`) : null}
        iconType={icon.iconType}
      >
        <CvtTasksNotCompleted />
      </StepStatusSection>
    )
  }
  const isAvailableCreditEqualApprovaleAmount = availableCreditEqualApprovedAmount(creditApp)
  const showCreateComponent =
    (creditApp.worksheet == null ||
      creditApp.worksheet.status === EWorksheetStatus.Draft ||
      creditApp.worksheet.finalDecision.decision === Enum.EDecision.manualRevision) &&
    canEdit

  const worksheetFinalDecisionAccepted = creditApp.worksheet?.finalDecision.decision === Enum.EDecision.accept
  const contractTileTitle =
    worksheetFinalDecisionAccepted &&
    (creditApp.contract.status === EContractStatus.completed ||
      creditApp.contract.status === EContractStatus.ready ||
      creditApp.contract.status === EContractStatus.sending)
      ? `${t('worksheet.contractNumber')} #${creditApp.contract.cvtNumber}`
      : t('viewCreditApplication.contractSectionTitle')
  return (
    <StepStatusSection
      title={contractTileTitle}
      iconMessage={icon.iconMessage ? t(`creditApplicationStatus.${icon.iconMessage}`) : null}
      iconType={icon.iconType}
    >
      {icon.iconMessage === Enum.ECreditApplicationStatus.Cancelled && (
        <Typography variant="body1">{t('viewCreditApplication.creditAppCancelled')}</Typography>
      )}

      {!isAvailableCreditEqualApprovaleAmount && showCreateComponent && (
        <Typography variant="body1">{t('viewCreditApplication.availableCreditNotCorresponding')}</Typography>
      )}

      {!showCreateComponent &&
        (creditApp.worksheet == null || !worksheetFinalDecisionAccepted) &&
        creditApp.status !== Enum.ECreditApplicationStatus.Cancelled && (
          <Typography>{t('worksheet.contractNotCreated')}</Typography>
        )}

      {isAvailableCreditEqualApprovaleAmount &&
        showCreateComponent &&
        creditApp.status !== Enum.ECreditApplicationStatus.Cancelled && <CreateContractButton creditApp={creditApp} />}

      {worksheetFinalDecisionAccepted && creditApp.contract.status === EContractStatus.completed && (
        <ContractCompleted creditApp={creditApp}></ContractCompleted>
      )}
      {worksheetFinalDecisionAccepted &&
        (creditApp.contract.status === EContractStatus.ready ||
          creditApp.contract.status === EContractStatus.sending) && (
          <ContractAwaitingSignature creditApp={creditApp}></ContractAwaitingSignature>
        )}
      {worksheetFinalDecisionAccepted && creditApp.contract.status === EContractStatus.notReady && isPolling && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {worksheetFinalDecisionAccepted && creditApp.contract.status === EContractStatus.notReady && !isPolling && (
        <div>
          <Typography> {t('worksheet.error')}</Typography>
        </div>
      )}
    </StepStatusSection>
  )
}
