import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Grid, IconButton, Menu, MenuItem, Paper, Stack, Typography } from '@mui/material'
import ActionsDialog from '@src/components/ActionsDialog'
import PageSpinner from '@src/components/PageSpinner'
import { useDeleteCoapplicant } from '@src/data/api/credit-api/credit-api'
import { formatPhone } from '@src/services/Formatter'
import { Enum } from '@src/types/Constants'
import { ApplicantDto } from '@src/types/CreditApplicationSchema'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import EditApplicantDialog from './EditApplicantDialog'

type props = {
  applicant: ApplicantDto
  applicantTitle: string
  applicantType: string
  creditApplicationId: string
  prohibitedPhone?: string
  disabled: boolean
}

function ApplicantInfoBox({
  applicant,
  applicantTitle,
  applicantType,
  creditApplicationId,
  prohibitedPhone,
  disabled,
}: props) {
  const { t } = useTranslation()
  const [isEditApplicantOpen, setIsEditApplicantOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const navigate = useNavigate()
  const [deleteCoapplicant, isDeleting] = useDeleteCoapplicant()

  const applicantFullname = `${applicant.firstName} ${applicant.lastName}`
  const applicantInfoBoxTitle = `${applicantTitle}`
  const phone = formatPhone(applicant.cellPhone)
  const preferredLanguage = () => {
    if (applicant.languageId === Enum.language.English) {
      return t('common.english')
    } else {
      return t('common.french')
    }
  }

  const handleEditOpen = useCallback(() => {
    setIsEditApplicantOpen(true)
  }, [])

  const handleEditClose = useCallback(() => {
    setIsEditApplicantOpen(false)
  }, [])

  const handleOpenMenu = useCallback((event) => {
    setIsMenuOpen(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(null)
  }, [])

  const handleConfirmDialog = useCallback(async () => {
    setIsDialogOpen(false)
    await deleteCoapplicant(creditApplicationId)
  }, [creditApplicationId, deleteCoapplicant])

  return (
    <Paper
      elevation={2}
      component={Grid}
      item
      xs={3.85}
      sx={{
        borderRadius: 2,
        p: 1,
        m: 1,
      }}
    >
      <PageSpinner isLoading={isDeleting} withBackdrop />

      <Stack direction="column" justifyContent="space-between" height="100%" spacing={1}>
        <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center' }}>
          <span>{applicantInfoBoxTitle}:</span>
          {!disabled && (
            <>
              <IconButton style={{ marginLeft: 'auto' }} aria-label="close" onClick={handleOpenMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="dropdown-menu"
                anchorEl={isMenuOpen}
                keepMounted
                open={Boolean(isMenuOpen)}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    handleEditOpen()
                    setIsMenuOpen(null)
                  }}
                >
                  {t('common.edit')}
                </MenuItem>

                {applicantType === Enum.EApplicantType.Coapplicant && (
                  <MenuItem
                    onClick={() => {
                      navigate(`/credit/${creditApplicationId}/coapplicant`)
                    }}
                  >
                    {t('common.replace')}
                  </MenuItem>
                )}

                {applicantType === Enum.EApplicantType.Coapplicant && (
                  <MenuItem
                    onClick={() => {
                      setIsMenuOpen(null)
                      setIsDialogOpen(true)
                    }}
                  >
                    {t('common.delete')}
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </Typography>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight={'bold'} gutterBottom>
              {t('viewCreditApplication.name')}
            </Typography>
            <Grid item sx={{ mb: 3 }}>
              <Typography variant="body1">{applicantFullname}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{ pl: 2 }}>
            <Typography variant="body1" fontWeight={'bold'} gutterBottom>
              {t('viewCreditApplication.phone')}
            </Typography>
            <Grid item>
              <Typography variant="body1"> {phone}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight={'bold'} gutterBottom>
              {t('viewCreditApplication.email')}
            </Typography>
            <Grid item xs={12}>
              <Typography variant="body1"> {applicant.email}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{ pl: 2 }}>
            <Typography variant="body1" fontWeight={'bold'} gutterBottom>
              {t('viewCreditApplication.preferredLanguage')}
            </Typography>
            <Grid item>
              <Typography variant="body1"> {preferredLanguage()}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {isEditApplicantOpen && (
          <EditApplicantDialog
            open={isEditApplicantOpen}
            handleClose={handleEditClose}
            applicant={applicant}
            creditApplicationId={creditApplicationId}
            applicantType={applicantType}
            prohibitedPhone={prohibitedPhone}
          />
        )}
      </Stack>
      <ActionsDialog
        open={isDialogOpen}
        onCancel={() => {
          setIsDialogOpen(false)
        }}
        onConfirm={handleConfirmDialog}
        title={t('viewCreditApplication.deleteCoapplican')}
      >
        <Typography variant="h5">{t('viewCreditApplication.confirmDeletionMessage')}</Typography>
      </ActionsDialog>
    </Paper>
  )
}
export default ApplicantInfoBox
