import { Enum } from '@src/types/Constants'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function useDocumentStatus(documentRequired: RequiredDocument) {
  const { t } = useTranslation()

  const { statusMessage, textColor, backgroundColor } = useMemo(() => {
    let statusMessage = t('documentStatuses.awaitingUpload')
    let textColor = Enum.StatusTextColors.warning
    let backgroundColor = Enum.StatusBackgroundColors.warning

    switch (documentRequired.status) {
      case Enum.DocumentStatus.Approved:
        statusMessage = t('documentStatuses.approved')
        textColor = Enum.StatusTextColors.success
        backgroundColor = Enum.StatusBackgroundColors.success
        break
      case Enum.DocumentStatus.AwaitingApproval:
        statusMessage = t('documentStatuses.awaitingApproval')
        textColor = Enum.StatusTextColors.info
        backgroundColor = Enum.StatusBackgroundColors.info
        break
      case Enum.DocumentStatus.Refused:
        statusMessage = t('documentStatuses.refused')
        textColor = Enum.StatusTextColors.error
        backgroundColor = Enum.StatusBackgroundColors.error
        break
      case Enum.DocumentStatus.Incomplete:
        statusMessage = t('documentStatuses.incomplete')
        textColor = Enum.StatusTextColors.warning
        backgroundColor = Enum.StatusBackgroundColors.warning
        break
      default:
    }
    return { statusMessage, textColor, backgroundColor }
  }, [documentRequired.status, t])

  return { statusMessage, textColor, backgroundColor }
}

export function useFullNameText(documentRequired: RequiredDocument) {
  const { t } = useTranslation()

  const fullNameText = useMemo(() => {
    let applicantSuffix = ''
    if (documentRequired.applicantType === Enum.EApplicantType.Applicant) {
      applicantSuffix = ` - ${t('common.applicant')}`
    } else if (documentRequired.applicantType === Enum.EApplicantType.Coapplicant) {
      applicantSuffix = ` - ${t('common.coApplicant')}`
    }

    return t('common.currentLanguage') === 'Fr'
      ? `${documentRequired.nameFr}${applicantSuffix}`
      : `${documentRequired.nameEn}${applicantSuffix}`
  }, [documentRequired, t])

  return fullNameText
}
