import React from 'react'
import { Grid } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { ApplicantJob, JobSchema } from '@src/types/CreditApplicationSchema'
import { Address } from '@src/types/AddressSchema'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { Enum } from '@src/types/Constants'
import InputTextFieldWithMask from '@src/components/InputMask'
import AddressComponent from './AddressComponent'
import ActionsDialog from '@src/components/ActionsDialog'
import { SelectValueListItem } from '@src/types/SelectValueListItem'

type Props = {
  open: boolean
  title: string
  defaultValue: ApplicantJob
  onConfirm: (data: ApplicantJob) => void
  onCancel: () => void
}

function EditJobDialog({ defaultValue, onConfirm, onCancel, open, title }: Props) {
  const { t } = useTranslation()

  const {
    register,
    trigger,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<ApplicantJob>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: defaultValue,
    resolver: yupResolver(JobSchema),
  })

  React.useEffect(() => {
    reset(defaultValue)
  }, [defaultValue, reset])

  const isAddressEmpty = (address: Address | null) => {
    return Object.values(address).every((a) => a === null || a === undefined)
  }

  const handleSubmit = async () => {
    await trigger(['annualSalary', 'jobType', 'jobTitle', 'employerName', 'years', 'months']).then((isValid) => {
      if (isValid) {
        const jobCopy = { ...getValues() }
        if (isAddressEmpty(jobCopy.address)) jobCopy.address = null
        onConfirm(jobCopy)
      }
    })
  }

  return (
    <ActionsDialog title={title} open={open} onCancel={onCancel} onConfirm={handleSubmit}>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={3}>
          <InputTextField
            id="annualSalary"
            type="number"
            error={errors?.annualSalary}
            label={t('editCreditApplication.income.job.annualSalary')}
            {...register('annualSalary')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            valueList={Object.values(Enum.EJobType).map((item) => {
              const data: SelectValueListItem = { label: t(`jobType.${item}`), value: item }
              return data
            })}
            label={t('editCreditApplication.income.job.jobType')}
            {...register('jobType')}
            error={errors?.jobType}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            id="jobTitle"
            inputProps={{ maxLength: 50 }}
            error={errors?.jobTitle}
            label={t('editCreditApplication.income.job.jobTitle')}
            {...register('jobTitle')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            id="employerName"
            error={errors?.employerName}
            inputProps={{ maxLength: 100 }}
            label={t('editCreditApplication.income.job.employerName')}
            {...register('employerName')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name="employerPhone"
            control={control}
            render={({ field }) => (
              <InputTextFieldWithMask
                id="employerPhone"
                mask={Enum.EMask.phoneNumberMask}
                error={errors?.employerPhone}
                label={t('editCreditApplication.income.job.employerPhone')}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} md={1}>
          <InputTextField
            id="employerPhoneExt"
            error={errors?.employerPhoneExt}
            label={t('editCreditApplication.income.job.employerPhoneExt')}
            {...register('employerPhoneExt')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            id="jobSinceYears"
            type="number"
            error={errors?.years}
            label={t('editCreditApplication.address.since.years')}
            {...register('years')}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputTextField
            id="jobSinceMonths"
            type="number"
            error={errors?.months}
            label={t('editCreditApplication.address.since.months')}
            {...register('months')}
          />
        </Grid>
        <AddressComponent
          formControl={control}
          register={register}
          errors={errors?.address}
          isCommercial
          name="address"
          includeDuration={false}
        />
      </Grid>
    </ActionsDialog>
  )
}

export default React.memo(EditJobDialog)
