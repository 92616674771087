import { DocTypes } from '@src/types/Constants'

export const getIncomeVerificationDocumentsTypes = [
  DocTypes.DisabilityIncomeProof,
  DocTypes.LastPayStubJob,
  DocTypes.SixMonthsPersonalBankStatements,
  DocTypes.VoidCheque,
  DocTypes.FamilyAllocationsProof,
  DocTypes.WorkIncidentIndemnisationProof,
  DocTypes.MaternityPaternityIncomeProof,
  DocTypes.OtherIncomeProof,
  DocTypes.RetirementIncomeProof,
  DocTypes.AssistanceProof,
  DocTypes.EmployementInsuranceProof,
]

export const getIdentityDocumentTypes = [DocTypes.ValidPhotoId]
