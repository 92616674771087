import { computeFirstPaymentDateOptions, getFormattedPlan } from '@src/data/worksheet-selectors'
import { formatUtcIsoDateTime } from '@src/services/Formatter'
import { EPaymentPlan } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { UserDtoFromJwt } from '@src/types/User'
import { isAfter, isWithinInterval } from 'date-fns'
import React, { useEffect, useMemo } from 'react'

export function getPossibleTerms(maxTermDuration: number) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    const supportedTerms = [12, 18, 24, 36, 48, 60, 72]
    return supportedTerms.filter((val) => val <= (maxTermDuration ?? 0))
  }, [maxTermDuration])
}

export const useComputeFirstPaymentDateOptions = (activationDate: string, listHolidays: Date[]) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    return computeFirstPaymentDateOptions(activationDate, listHolidays)
  }, [activationDate, listHolidays])
}

export const usePaymentPlanList = (creditApp: CreditApplication, merchant: Merchant) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    const selectValueList: { label: string; value: string; id: string }[] = []

    selectValueList.push({
      value: EPaymentPlan.regularDailyInterests,
      label: EPaymentPlan.regularDailyInterests,
      id: merchant?.id,
    })

    const currentDate = new Date()
    const creationDate = new Date(creditApp?.createdOn)
    const DISTANT_FUTURE = new Date()
    DISTANT_FUTURE.setFullYear(DISTANT_FUTURE.getFullYear() + 100)

    const filteredPaymentPlans = merchant?.paymentPlans?.filter((plan) => {
      const planActiveFrom = new Date(plan.activeFrom)
      const planExpiresOn = new Date(plan?.expiresOn ?? DISTANT_FUTURE)

      const currentUtcDateFormatted = new Date(formatUtcIsoDateTime(new Date()))
      return (
        (isWithinInterval(creationDate, { start: planActiveFrom, end: planExpiresOn }) ||
          isWithinInterval(currentDate, { start: planActiveFrom, end: planExpiresOn })) &&
        isAfter(planExpiresOn, currentUtcDateFormatted)
      )
    })

    filteredPaymentPlans?.forEach((p) => {
      selectValueList.push({ value: p.id, label: p.promoCode + ' ' + getFormattedPlan(p), id: p.id })
    })

    return selectValueList
  }, [creditApp?.createdOn, merchant?.id, merchant?.paymentPlans])
}

export const getMerchantList = (
  creditApplication: CreditApplication,
  merchantsListFromParentId: Merchant[],
  merchant: Merchant,
  user: UserDtoFromJwt,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [merchantList, setMerchantList] = React.useState<Merchant[]>([])
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    let newMerchantList = []
    if (creditApplication?.worksheet && user.isBannerUser) {
      const merchantIds = creditApplication.worksheet.merchantPayments.map((payment) => payment.merchantId)
      newMerchantList = merchantsListFromParentId.filter((merchant) => merchantIds.includes(merchant.id))
    } else if (merchant) {
      newMerchantList = [merchant]
    }
    return setMerchantList(newMerchantList)
  }, [creditApplication, merchant, merchantsListFromParentId, user.isBannerUser])
  return { merchantList, setMerchantList }
}
