import { Tooltip } from '@mui/material'

type props = {
  message: string
  color?: string
}

export default function DocumentNote({ message, color }: props) {
  return (
    <>
      <Tooltip title={message} placement="top">
        <div
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
            color: color,
          }}
        >
          {message}
        </div>
      </Tooltip>
    </>
  )
}
