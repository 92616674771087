import AsyncActionButton from '@src/components/AsyncActionButton'
import { Enum } from '@src/types/Constants'
import { ApplicantDto } from '@src/types/CreditApplicationSchema'
import { t } from 'i18next'
import ResetBankAccountButton from './ResetBankAccountButton'
import { useContinueWithComputedIncome } from '@src/data/api/credit-api/credit-api'
import { useCanContinueWithComputedIncome } from './incomeVerification-hooks'

type props = {
  applicant: ApplicantDto
  hasIncomeDocumentsPendingUpload: boolean
  hasIncomeDocumentsPendingReview: boolean
  hasIncomeServiceAdjustment: boolean
  creditApplicationId: string
  canEdit: boolean
}

export default function IncomeVerificationMismatch({
  applicant,
  hasIncomeDocumentsPendingUpload,
  hasIncomeDocumentsPendingReview,
  hasIncomeServiceAdjustment,
  creditApplicationId,
  canEdit,
}: props) {
  const [continueWithComputedIncome, isContinuingWithComputedIncome, isError, isSuccess] =
    useContinueWithComputedIncome()

  const applicantType = applicant.isPrimaryApplicant ? Enum.EApplicantType.Applicant : Enum.EApplicantType.Coapplicant

  const canContinueWithComputedIncome = useCanContinueWithComputedIncome(applicant)

  const onContinueWithComputedIncome = async () => {
    await continueWithComputedIncome({ creditApplicationId, applicantType })
  }
  const incomeMismatchUnderReview =
    (hasIncomeDocumentsPendingReview || !applicant.incomeVerification?.allIncomeTasksConfirmed) &&
    !hasIncomeDocumentsPendingUpload

  return (
    <div>
      {incomeMismatchUnderReview && (
        <>
          {!hasIncomeServiceAdjustment && <p>{t('incomeVerification.incomeMismatch.noMatch')}</p>}
          <p>{t('incomeVerification.startVerification.reviewing')}</p>
          <p>{t('incomeVerification.startVerification.notified')}</p>
        </>
      )}

      <>
        {hasIncomeDocumentsPendingUpload && (
          <>
            <p>{t('incomeVerification.incomeMismatch.noMatchProvideDocs')}</p>
            {canContinueWithComputedIncome && <p>{t('incomeVerification.incomeMismatch.continueOrSubmit')}</p>}
          </>
        )}
        {!hasIncomeServiceAdjustment && canContinueWithComputedIncome && canEdit && (
          <AsyncActionButton
            variant="contained"
            color="primary"
            onClick={onContinueWithComputedIncome}
            isPending={isContinuingWithComputedIncome}
            isError={isError}
            isSuccess={isSuccess}
          >
            {t('incomeVerification.incomeMismatch.continue')}
          </AsyncActionButton>
        )}
        {!hasIncomeServiceAdjustment && canEdit && (
          <>
            <p>{t('incomeVerification.nameMismatch.reset')}</p>
            <ResetBankAccountButton applicantType={applicantType} id={creditApplicationId} />
          </>
        )}
      </>
    </div>
  )
}
