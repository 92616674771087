import { Typography } from '@mui/material'
import { shouldDisplayInterest } from '@src/data/creditapp-selectors'
import { FormatCurrency } from '@src/services/Formatter'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { t } from 'i18next'
type props = {
  creditApp: CreditApplication
}
export default function PrequalApproved({ creditApp }: props) {
  const mustDisplayInterests = shouldDisplayInterest(creditApp)
  return (
    <>
      {mustDisplayInterests && (
        <>
          <div>
            <Typography variant="subtitle1">{t('viewCreditApplication.interestRate')}</Typography>
            <Typography variant="body1">
              {creditApp.prequalificationDecision.minInterestRate} -{' '}
              {creditApp.prequalificationDecision.maxInterestRate}%
            </Typography>
          </div>
        </>
      )}
      <div>
        <Typography variant="subtitle1">{t('viewCreditApplication.amountRequested')}</Typography>
        <Typography variant="body1">{FormatCurrency(creditApp.requestedLoanAmount)}</Typography>
      </div>
    </>
  )
}
