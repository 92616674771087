import AsyncActionButton from '@src/components/AsyncActionButton'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { EWorksheetStatus } from '@src/types/Constants'
type props = {
  creditApp: CreditApplication
}
export default function CreateContractButton({ creditApp }: props) {
  const isDraft = creditApp.worksheet?.status === EWorksheetStatus.Draft
  const buttonText =
    creditApp.worksheet != null && !isDraft ? t('worksheet.editWorksheet') : t('worksheet.createContract')
  const navigate = useNavigate()

  return (
    <>
      <AsyncActionButton fullWidth variant="contained" onClick={() => navigate(`/credit/${creditApp.id}/Worksheet`)}>
        {buttonText}
      </AsyncActionButton>
    </>
  )
}
