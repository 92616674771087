import { CreditApplication } from '@src/types/CreditApplicationSchema'
import ContractInformation from './ContractInformation'
import DownloadContractButton from './DownloadContractButton'
import { Enum } from '@src/types/Constants'

type props = {
  creditApp: CreditApplication
}
export default function ContractCompleted({ creditApp }: props) {
  return (
    <>
      <ContractInformation creditApp={creditApp} />
      {creditApp.originSystemId === Enum.EOriginSystemId.MerchantDashboard && (
        <DownloadContractButton creditApp={creditApp} />
      )}
    </>
  )
}
