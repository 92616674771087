import { Divider, Grid, Typography } from '@mui/material'
import { DatePickerInput } from '@src/components/DatePicker'
import InputTextFieldWithMask from '@src/components/InputMask'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { Constants, Enum } from '@src/types/Constants'
import { ApplicantDto, CreditApplication } from '@src/types/CreditApplicationSchema'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import { addYears } from 'date-fns'
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  Merge,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  register: UseFormRegister<CreditApplication | ApplicantDto>
  errors: Merge<FieldError, FieldErrors<ApplicantDto>> | undefined
  name: 'coApplicant.' | 'applicant.' | ''
  formControl: Control<CreditApplication | ApplicantDto>
  setValue: UseFormSetValue<CreditApplication | ApplicantDto>
  getValues: UseFormGetValues<CreditApplication | ApplicantDto>
  prohibitedPhone?: string
}

function ApplicantInformationParameters({
  register,
  errors,
  name,
  formControl,
  setValue,
  getValues,
  prohibitedPhone,
}: Props) {
  const { t } = useTranslation()

  const handlePhoneOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const phone = value.replace(/-/g, '')
    if (prohibitedPhone && prohibitedPhone === phone) {
      event.target.setCustomValidity(t('common.errors.coappSameMobileError'))
    } else {
      event.target.setCustomValidity('')
      setValue(`${name}cellPhone`, phone)
    }
  }

  return (
    <>
      <input type="hidden" {...register(`${name}id`)} />
      <Divider style={{ fontSize: 25 }}>
        <Typography variant="h6" gutterBottom>
          {t('editCreditApplication.information.label')}
        </Typography>
      </Divider>

      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={3}>
          <InputTextField
            id="applicantFirstName"
            error={errors?.firstName}
            label={t('editCreditApplication.information.firstName')}
            {...register(`${name}firstName`)}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            id="applicantMiddleName"
            error={errors?.middleName}
            label={t('editCreditApplication.information.middleName')}
            {...register(`${name}middleName`)}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            id="applicantLastName"
            error={errors?.lastName}
            label={t('editCreditApplication.information.lastName')}
            {...register(`${name}lastName`)}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerInput
            name={`${name}birthDate`}
            control={formControl}
            label={t('editCreditApplication.information.birthDate')}
            error={errors?.birthDate}
            minDate={addYears(new Date(), -Constants.MaxYearsToDisplay)}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`${name}sin`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                id="sin"
                mask={Enum.EMask.sinMask}
                error={errors?.sin}
                label={t('editCreditApplication.information.sin')}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            valueList={Object.values(Enum.EGender).map((item) => {
              const data: SelectValueListItem = { label: `genderType.${item}`, value: item }
              return data
            })}
            label={t('editCreditApplication.information.genderId')}
            {...register(`${name}genderId`)}
            error={errors?.genderId}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            valueList={Object.values(Enum.EMaritalStatus).map((item) => {
              const data: SelectValueListItem = { label: `maritalStatus.${item}`, value: item }
              return data
            })}
            label={t('editCreditApplication.information.maritalStatusId')}
            {...register(`${name}maritalStatusId`)}
            error={errors?.maritalStatusId}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            valueList={Object.values(Enum.ELanguage).map((item) => {
              const data: SelectValueListItem = { label: `language.${item}`, value: item }
              return data
            })}
            label={t('editCreditApplication.information.languageId')}
            {...register(`${name}languageId`)}
            error={errors?.languageId}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`${name}homePhone`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                id="homePhone"
                mask={Enum.EMask.phoneNumberMask}
                error={errors?.homePhone}
                label={t('editCreditApplication.information.homePhone')}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`${name}cellPhone`}
            control={formControl}
            render={() => (
              <InputTextFieldWithMask
                defaultValue={getValues(`${name}cellPhone`)}
                id="cellPhone"
                mask={Enum.EMask.phoneNumberMask}
                error={errors?.cellPhone}
                label={t('editCreditApplication.information.cellPhone')}
                onInput={handlePhoneOnchange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            id="email"
            error={errors?.email}
            label={t('editCreditApplication.information.email')}
            {...register(`${name}email`)}
          />
        </Grid>
        {name !== 'applicant.' && (
          <Grid item xs={12} md={3}>
            <SelectComponent
              valueList={Object.values(Enum.ERelationToApplicant).map((item) => {
                const data: SelectValueListItem = { label: `relationToApplicant.${item}`, value: item }
                return data
              })}
              label={t('editCreditApplication.information.relationWithApplicant')}
              {...register(`${name}relationWithApplicant`)}
              error={errors?.relationWithApplicant}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}
export default ApplicantInformationParameters
