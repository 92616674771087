import {
  MutationFunction,
  QueryFunctionContext,
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { getApiClient } from '../api-client'
import { EContractStatus, Enum } from '@src/types/Constants'
import { ComputePersonalLoanFundingDto } from '@src/types/ComputePersonalLoanFundingDto'
import { ComputePersonalLoanFundingResultDto } from '@src/types/ComputePersonalLoanFundingResultDto'
import { ElectronicSignature } from '@src/types/ElectronicSignature'
import { isComputePersonalLoanDtoValid } from '@src/data/worksheet-selectors'
import { FilteredWorksheet } from '@src/types/FilteredWorksheet'
import { EditPersonalLoanWorksheetDto } from '@src/types/WorksheetSchema'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { ApiError } from '@src/types/common/ApiError'

const SCOPE = 'worksheet'
const CREDIT_APP_SCOPE = 'credit-applications'
const SCOPEHOLIDAYS = 'holidays'
const SCOPEPERSOFUNDING = 'personalFunding'
const DETAIL = 'detail'
const keysFactory = {
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  holidays: (year: number) => [{ scope: SCOPEHOLIDAYS, year }] as const,
  personalFunding: (id: string, dto: ComputePersonalLoanFundingDto) =>
    [{ scope: SCOPEPERSOFUNDING, id, ...dto }] as const,
  creditAppDetail: (id: string) => [{ scope: CREDIT_APP_SCOPE, entity: DETAIL, id }] as const,
}

const GetHolidaysForYear = async ({
  queryKey: [{ year }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['holidays']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get(`/Holidays/${encodeURIComponent(year)}`)
  return response.data
}

export function useGetHolidaysForYear(year: number): [Date[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.holidays(year),
    queryFn: GetHolidaysForYear,
  })

  return [data, isFetching]
}
const GetPersonalFunding = async ({
  queryKey: [{ id, ...dto }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['personalFunding']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get(
    `/Worksheet/${Enum.EFinancingProgram.Personal}/${encodeURIComponent(id)}/ComputePersonalLoanFunding/`,
    {
      params: dto,
    },
  )
  return response.data
}

export function useGetPersonalFunding(
  id: string,
  dto: ComputePersonalLoanFundingDto,
): [ComputePersonalLoanFundingResultDto, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.personalFunding(id, dto),
    queryFn: GetPersonalFunding,
    enabled: isComputePersonalLoanDtoValid(dto),
  })

  return [data, isFetching]
}

const sendSignatureLink: MutationFunction<
  ElectronicSignature,
  { financingProgramId: string; creditApplicationId: string }
> = async ({ financingProgramId, creditApplicationId }) => {
  const apiClient = getApiClient()
  const response = await apiClient.post(`/Contract/${financingProgramId}/${creditApplicationId}/SendSignatureLink`)
  return response.data as ElectronicSignature
}

export function useSendSignatureLink(
  financingProgramId: string,
  creditApplicationId: string,
): [UseMutateAsyncFunction<ElectronicSignature, Error, string, unknown>, boolean, boolean, boolean] {
  const { mutateAsync, isPending, isError, isSuccess } = useMutation<ElectronicSignature, Error, string>({
    mutationFn: () => sendSignatureLink({ financingProgramId, creditApplicationId }),
  })

  return [mutateAsync, isPending, isError, isSuccess]
}

const postWorksheet: MutationFunction<FilteredWorksheet, { worksheet: EditPersonalLoanWorksheetDto }> = async ({
  worksheet,
}) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<FilteredWorksheet>(
    `/Worksheet/${Enum.EFinancingProgram.Personal}/${encodeURIComponent(worksheet.id)}`,
    worksheet,
  )

  return response.data
}

export function usePostWorksheet(): [
  MutationFunction<FilteredWorksheet, { worksheet: EditPersonalLoanWorksheetDto }>,
  boolean,
  boolean,
  ApiError | null,
  () => void,
] {
  const queryClient = useQueryClient()
  const { isPending, isError, mutateAsync, error, reset } = useMutation({
    mutationFn: postWorksheet,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.creditAppDetail(data.id), (oldData: CreditApplication) => {
        return {
          ...oldData,
          worksheet: data,
          updatedOn: data.updatedOn,
          contract: { ...oldData.contract, status: EContractStatus.notReady },
        }
      })
    },
  })

  return [mutateAsync, isPending, isError, error as ApiError, reset]
}

const putWorksheet: MutationFunction<FilteredWorksheet, { worksheet: EditPersonalLoanWorksheetDto }> = async ({
  worksheet,
}) => {
  const apiClient = getApiClient()
  const response = await apiClient.put<FilteredWorksheet>(
    `/Worksheet/${Enum.EFinancingProgram.Personal}/${encodeURIComponent(worksheet.id)}`,
    worksheet,
  )
  return response.data
}

export function usePutWorksheet(): [
  MutationFunction<FilteredWorksheet, { worksheet: EditPersonalLoanWorksheetDto }>,
  boolean,
  boolean,
  ApiError | null,
  () => void,
] {
  const queryClient = useQueryClient()
  const { isPending, isError, error, mutateAsync, reset } = useMutation({
    mutationFn: putWorksheet,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.creditAppDetail(data.id), (oldData: CreditApplication) => {
        return {
          ...oldData,
          worksheet: data,
          updatedOn: data.updatedOn,
          contract: { ...oldData.contract, status: EContractStatus.notReady },
        }
      })
    },
  })

  return [mutateAsync, isPending, isError, error as ApiError, reset]
}
