import { CircleNotifications as CircleNotificationsIcon } from '@mui/icons-material/'
import Tooltip from '@mui/material/Tooltip'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { List, ListItem } from '@mui/material'
import { useCallback } from 'react'
import { Enum } from '@src/types/Constants'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import VisibilityIcon from '@mui/icons-material/Visibility'

type props = {
  merchantNotificationCodes: string[]
  requiredDocuments: RequiredDocument[]
  originSystemId: string
}
const MerchantNotificationIcons = ({ merchantNotificationCodes, requiredDocuments, originSystemId }: props) => {
  const FR = 'fr'
  const { i18n } = useTranslation()
  const getFullDocumentName = useCallback(
    (documentRequired: RequiredDocument) => {
      let applicantSuffix = ''
      if (documentRequired.applicantType === Enum.EApplicantType.Applicant) {
        applicantSuffix = ` - ${t('common.applicant')}`
      } else if (documentRequired.applicantType === Enum.EApplicantType.Coapplicant) {
        applicantSuffix = ` - ${t('common.coApplicant')}`
      }

      return i18n.language === FR
        ? `${documentRequired.nameFr}${applicantSuffix}`
        : `${documentRequired.nameEn}${applicantSuffix}`
    },
    [i18n],
  )

  return (
    <div>
      {merchantNotificationCodes.length > 0 && (
        <Tooltip
          title={merchantNotificationCodes.map((notification, index) => (
            <div key={notification}>
              {t(`notificationsTitles.${notification}`)}
              {index !== merchantNotificationCodes.length - 1 && (
                <>
                  <br />
                  <br />
                </>
              )}
            </div>
          ))}
        >
          <CircleNotificationsIcon />
        </Tooltip>
      )}
      {requiredDocuments.length > 0 && originSystemId === Enum.EOriginSystemId.MerchantDashboard && (
        <Tooltip
          title={
            <List>
              {t(`common.requiredDocuments`)}
              {requiredDocuments.map((doc) => {
                return <ListItem key={doc.typeId}>{getFullDocumentName(doc)}</ListItem>
              })}
            </List>
          }
        >
          <UploadFileRoundedIcon />
        </Tooltip>
      )}
      {originSystemId !== Enum.EOriginSystemId.MerchantDashboard && (
        <Tooltip
          title={
            originSystemId === Enum.EOriginSystemId.Backoffice
              ? t('viewCreditApplication.readOnlyBackOffice')
              : t('viewCreditApplication.readOnlySubscription')
          }
        >
          <VisibilityIcon />
        </Tooltip>
      )}
    </div>
  )
}

export default MerchantNotificationIcons
