import { ECurrentCustomerSteps, Enum } from '@src/types/Constants'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function useCreditApplicationStatuses() {
  const { t } = useTranslation()

  return useMemo(
    () => [
      { label: t('common.all'), value: '' },
      { label: t('creditApplicationStatus.active'), value: Enum.ECreditApplicationStatus.Active },
      { label: t('creditApplicationStatus.cancelled'), value: Enum.ECreditApplicationStatus.Cancelled },
      { label: t('creditApplicationStatus.completed'), value: Enum.ECreditApplicationStatus.Completed },
      { label: t('creditApplicationStatus.draft'), value: Enum.ECreditApplicationStatus.Draft },
      { label: t('creditApplicationStatus.expired'), value: Enum.ECreditApplicationStatus.Expired },
    ],
    [t],
  )
}

export function useCurrentCustomerSteps() {
  const { t } = useTranslation()

  return useMemo(
    () => [
      { label: t('common.all'), value: '' },
      { label: t('dashboardEntriesStatus.prequal'), value: ECurrentCustomerSteps.Prequal },
      { label: t('dashboardEntriesStatus.credit'), value: ECurrentCustomerSteps.Credit },
      { label: t('dashboardEntriesStatus.incomeVerification'), value: ECurrentCustomerSteps.IncomeVerification },
      { label: t('dashboardEntriesStatus.contract'), value: ECurrentCustomerSteps.Contract },
      { label: t('dashboardEntriesStatus.funding'), value: ECurrentCustomerSteps.Funding },
    ],
    [t],
  )
}
