import AsyncActionButton from '@src/components/AsyncActionButton'
import { usePostStatus } from '@src/data/api/credit-api/credit-api'
import { EContractStatus, Enum } from '@src/types/Constants'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import CancelReasonsDialog from './CancelReasonsDialog'
import { CreditApplication } from '@src/types/CreditApplicationSchema'

type props = {
  creditApp: CreditApplication
}

export default function CancelCreditAppButton({ creditApp }: props) {
  const [postStatus, isPosting, isError, isSuccess] = usePostStatus()
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)

  const handleButtonClick = useCallback(async () => {
    creditApp.status === Enum.ECreditApplicationStatus.Active
      ? setIsCancelDialogOpen(true)
      : await postStatus({ id: creditApp.id, status: Enum.ECreditApplicationStatus.Active })
  }, [creditApp, postStatus])

  const handleEditClose = useCallback(() => {
    setIsCancelDialogOpen(false)
  }, [])

  const buttonText =
    creditApp.status === Enum.ECreditApplicationStatus.Cancelled
      ? t('viewCreditApplication.reactivate')
      : t('common.cancel')
  return (
    <>
      <AsyncActionButton
        fullWidth
        variant="contained"
        isPending={isPosting}
        isError={isError}
        isSuccess={isSuccess}
        onClick={handleButtonClick}
        disabled={creditApp?.contract?.status === EContractStatus.completed}
      >
        {buttonText}
      </AsyncActionButton>
      {isCancelDialogOpen && (
        <CancelReasonsDialog
          open={isCancelDialogOpen}
          handleClose={handleEditClose}
          creditApplicationId={creditApp.id}
        />
      )}
    </>
  )
}
