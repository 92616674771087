import { Typography } from '@mui/material'
import { t } from 'i18next'
import CancelCreditAppButton from '../CancelCreditAppButton'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
type props = {
  creditApp: CreditApplication
}
export default function PrequalDocumentRequired({ creditApp }: props) {
  return (
    <>
      <Typography variant="body1">{t('viewCreditApplication.requiredDocuments')}</Typography>
      <CancelCreditAppButton creditApp={creditApp} />
    </>
  )
}
