import { green, grey, orange, red } from '@mui/material/colors'
import { DocTypes, Enum } from '@src/types/Constants'
import { RequiredDocument } from '@src/types/RequiredDocument'

export const getPrequalColor = (creditApplicationStatus: string, prequalDecision: string) => {
  if (creditApplicationStatus === Enum.ECreditApplicationStatus.Draft) {
    return grey[500]
  } else if (prequalDecision === Enum.Decision.Refused) {
    return red[500]
  } else if (prequalDecision === Enum.Decision.Approved) {
    return green[500]
  }
  return orange[500]
}

export const getCreditColor = (prequalDecision: string, hardHitDecision: string) => {
  if (hardHitDecision === Enum.Decision.Refused) {
    return red[500]
  } else if (hardHitDecision === Enum.Decision.Approved) {
    return green[500]
  } else if (
    prequalDecision === Enum.Decision.Approved &&
    (hardHitDecision === Enum.Decision.None ||
      (hardHitDecision !== Enum.Decision.Approved && hardHitDecision !== Enum.Decision.Refused))
  ) {
    return orange[500]
  }

  return grey[500]
}

export const getIncomeVerificationColor = (
  prequalDecision: string,
  hardHitDecision: string,
  isIncomeConfirmed: boolean,
) => {
  if (isIncomeConfirmed) {
    return green[500]
  } else if (prequalDecision === Enum.Decision.Approved && hardHitDecision === Enum.Decision.Approved) {
    return orange[500]
  }

  return grey[500]
}

export const getContractColor = (
  hardHitDecision: string,
  isIncomeConfirmed: boolean,
  isContractSignatureMethodSelected: boolean,
  missingRequiredDocuments: RequiredDocument[],
) => {
  if (hardHitDecision === Enum.Decision.Approved && isIncomeConfirmed === true) {
    const hasCvtSigned = !missingRequiredDocuments.some((doc) => doc.typeId === DocTypes.signedCvt)
    if (isContractSignatureMethodSelected === true && hasCvtSigned === true) {
      return green[500]
    } else {
      return orange[500]
    }
  }

  return grey[500]
}

export const getFundingColor = (
  status: string,
  hardHitDecision: string,
  isIncomeConfirmed: boolean,
  isContractSignatureMethodSelected: boolean,
) => {
  if (status === 'PP') {
    return green[500]
  } else if (
    isContractSignatureMethodSelected === true &&
    hardHitDecision === Enum.Decision.Approved &&
    isIncomeConfirmed === true
  ) {
    return orange[500]
  }

  return grey[500]
}
