import { CreditApplication } from '@src/types/CreditApplicationSchema'
import SignManuallyButton from './SignManuallyButton'
import CreateContractButton from './CreateContractButton'
import SignDigitallyButton from './SignDigitallyButton'
import ContractInformation from './ContractInformation'
import { Enum } from '@src/types/Constants'
import { CvtAwaitingApproval } from '@src/data/creditapp-selectors'

type props = {
  creditApp: CreditApplication
}
export default function ContractAwaitingSignature({ creditApp }: props) {
  return (
    <>
      <ContractInformation creditApp={creditApp} />
      {creditApp.originSystemId === Enum.EOriginSystemId.MerchantDashboard && (
        <>
          <SignDigitallyButton creditApp={creditApp} />
          <SignManuallyButton creditApp={creditApp} />
          {!CvtAwaitingApproval(creditApp.requiredDocuments) && <CreateContractButton creditApp={creditApp} />}
        </>
      )}
    </>
  )
}
