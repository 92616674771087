import { Box, Typography } from '@mui/material'
import { FundingSummaryDto } from '@src/data/api/credit-api/credit-api'
import { FormatCurrency } from '@src/services/Formatter'
import { useTranslation } from 'react-i18next'

interface Props {
  fundingSummary: FundingSummaryDto
}

export function LoanInformation({ fundingSummary }: Props) {
  const { t } = useTranslation()

  return (
    <Box sx={{ marginLeft: '2.5%' }}>
      <Typography variant="subtitle1" align="center">
        {t('fundingSummary.amountConfirmation')}
      </Typography>
      <Typography variant="h6" align="center" marginTop={0} gutterBottom>
        {FormatCurrency(fundingSummary?.financedAmount)}
      </Typography>

      <Typography variant="subtitle1" align="center" sx={{ marginTop: '5%' }}>
        {t('fundingSummary.paymentDisbursementDate')}
      </Typography>
      <Typography variant="h6" align="center" gutterBottom>
        {fundingSummary?.activationOn}
      </Typography>

      <Typography variant="subtitle1" align="center" sx={{ marginTop: '5%' }} gutterBottom>
        {t('fundingSummary.postDateDisbursement')}
      </Typography>
    </Box>
  )
}
