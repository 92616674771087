import { Divider, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

type Props = {
  canSkipFlinks: boolean
}

const FlinksOptionBox = ({ canSkipFlinks }: Props) => {
  const { t } = useTranslation()

  return (
    <Grid
      item
      sm={5}
      border={'2px solid'}
      borderColor="#D6D7D6DD"
      padding="2rem"
      paddingTop="1rem"
      borderRadius="1rem"
      marginRight="2rem"
    >
      {canSkipFlinks && (
        <>
          <Typography variant="h5">Option 1</Typography>
          <Divider sx={{ marginBottom: '1rem' }} />
        </>
      )}
      <Typography variant="h6" marginBottom="1rem">
        {t('incomeVerification.bankAccountProcedure.optionOne')}
      </Typography>
      <Link to={'https://flinks.com/about/'} style={{ color: '#90caf9' }} target="_blank">
        {t('incomeVerification.bankAccountProcedure.whatsFlinks')}
      </Link>
      <Typography color="primary" marginTop="1rem">
        {t('incomeVerification.bankAccountProcedure.step')} 1
      </Typography>
      <Typography>{t('incomeVerification.bankAccountProcedure.authenticate')}</Typography>
      <Typography color="primary" marginTop="1rem">
        {t('incomeVerification.bankAccountProcedure.step')} 2
      </Typography>
      <Typography>{t('incomeVerification.bankAccountProcedure.authorize')}</Typography>
      <Typography color="primary" marginTop="1rem">
        {t('incomeVerification.bankAccountProcedure.step')} 3
      </Typography>
      <Typography>{t('incomeVerification.bankAccountProcedure.automated')}</Typography>
    </Grid>
  )
}

export default FlinksOptionBox
