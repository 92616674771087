// doit être fait en deux lignes sinon webpack optimise et n'inclut pas la string
// de plus, le toString est requis car en local c'est un string alors qu'en dev c'est une string
const useDevToolsStr = process.env.REACT_APP_USE_DEV_TOOLS ?? 'false'
export const useDevTools = useDevToolsStr.toString() === 'true'

export class Enum {
  static readonly ServiceCategory = {
    Veterinary: 'veterinary',
    Medical: 'medical',
    Aesthetics: 'aesthetics',
    Dentistry: 'dentistry',
    GoodsAndServices: 'goodsAndServices',
  }

  static readonly MerchantType = {
    Merchant: 0,
    Banner: 1,
  }

  static readonly Decision = {
    Refused: 'refused',
    Approved: 'approved',
    None: '',
  }

  static readonly EFinancingProgram = {
    Auto: 'auto',
    IfXpress: 'ifxpress',
    Vlvr: 'vlvr',
    Products: 'products',
    Personal: 'personal',
  }

  static readonly EHomeStatus = {
    Owner: 'own',
    Renter: 'rent',
    OwnMobileHome: 'ownMobileHome',
    WithParents: 'withParents',
    ReserveHousing: 'reserveHousing',
    OwnWithMortgage: 'ownWithMortgage',
    Other: 'other',
  }

  static readonly EOtherIncomeTypes = {
    familyAllocations: 1,
    workIncidentIndemnisation: 3,
    maternityPaternityIncome: 4,
    other: 6,
    retirementIncome: 8,
    disabilityIncome: 11,
    incomeServiceAdjustment: 12,
    assistance: 13,
    employmentInsurance: 14,
  }

  static readonly ECreditApplicationStatus = {
    Draft: 'draft',
    Active: 'active',
    Completed: 'completed',
    Cancelled: 'cancelled',
    Expired: 'expired',
    Declined: 'declined',
    ManualRevision: 'manualRevision',
    DocumentsRequired: 'documentsRequired',
    Error: 'applicationError',
    Progress: 'inProgress',
    Waiting: 'waiting',
    Processing: 'processing',
    ActionRequired: 'actionRequired',
    AwaitingSignature: 'awaitingSignature',
    AwaitingApproval: 'awaitingApproval',
  }

  static readonly BeneficiaryType = {
    NotSet: 0,
    Applicant: 1,
    Coapplicant: 2,
    Other: 3,
  }

  static readonly EOriginSystemId = {
    Backoffice: 'backoffice',
    Subscription: 'ifinancesub',
    MerchantDashboard: 'merchdb',
  }

  static readonly EDecision = {
    pending: 'pending',
    accept: 'approved',
    manualRevision: 'manual-revision',
    declined: 'refused',
    error: 'error',
  }

  static readonly EProvince = {
    quebec: 'QC',
    ontario: 'ON',
    novaScotia: 'NS',
    newBrunswick: 'NB',
    princeEdwardIsland: 'PE',
    newfoundland: 'NL',
    britishColombia: 'BC',
    saskatchewan: 'SK',
    alberta: 'AB',
    manitoba: 'MB',
    yukon: 'YT',
    nunavut: 'NU',
    northwestTerritories: 'NT',
  }

  static readonly EMask = {
    phoneNumberMask: '000-000-0000',
    sinMask: '000 000 000',
    postalCode: 'a0a 0a0',
  }

  static readonly EGender = {
    men: 0,
    woman: 1,
    other: 2,
  }

  static readonly EMaritalStatus = {
    single: 0,
    married: 1,
    deFactoCouple: 2,
    widowWidower: 3,
    undefined: 999,
  }

  static readonly ELanguage = {
    french: 0,
    english: 1,
  }

  static readonly ERelationToApplicant = {
    Spouse: 'SP',
    CommonLaw: 'CL',
    Employer: 'Employer',
    InLaw: 'IL',
    Parental: 'PR',
    GrandMother: 'GM',
    GrandFather: 'GF',
    Brother: 'B',
    Sister: 'S',
    Other: 'Other',
  }

  static readonly EJobType = {
    fullTime: 0,
    partTime: 1,
    Seasonal: 3,
    SelfEmployed: 5,
  }

  static readonly DocumentStatus = {
    AwaitingDocument: 'awaitingDocument',
    AwaitingScan: 'awaitingScan',
    AwaitingApproval: 'awaitingApproval',
    Incomplete: 'incomplete',
    Deleted: 'deleted',
    Refused: 'refused',
    Approved: 'approved',
  }
  static readonly language = {
    French: 0,
    English: 1,
  }

  static readonly IconType = {
    success: 'success',
    warning: 'warning',
    error: 'error',
  }
  static readonly StatusTextColors = {
    success: 'green',
    warning: '#cf7b2f',
    error: 'red',
    info: '#0061c2',
  }
  static readonly StatusBackgroundColors = {
    success: '#90ee90',
    warning: '#fdf2e2',
    error: '#ffb6c1',
    info: '#a2caf3',
  }
  static readonly EApplicantType = {
    Applicant: 'applicant',
    Coapplicant: 'coApplicant',
  }
}

export enum ECurrentCustomerSteps {
  Draft = 'draft',
  Prequal = 'prequal',
  Credit = 'credit',
  IncomeVerification = 'incomeVerification',
  Contract = 'contract',
  Funding = 'funding',
}

export enum IconType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
  waiting = 'waiting',
}

export const Constants = {
  SystemUserId: 'system',
  MinimumLoanAmount: 500,
  MaximumLoanAmount: 40000,
  SupportedDocumentTypes: '.jpg, .jpeg, .png, .pdf, .heic, .heif',
  MaxYearsToDisplay: 125,
}

export const MinimumAgeRequirementByStateIso = {
  quebec: 18,
  ontario: 18,
  novaScotia: 19,
  newBrunswick: 19,
  princeEdwardIsland: 18,
  newfoundland: 19,
  britishColombia: 18,
  saskatchewan: 18,
  alberta: 18,
  manitoba: 18,
  yukon: 18,
  nunavut: 18,
  northwestTerritories: 18,
}

export enum EContractStatus {
  ready = 'ready',
  sending = 'sending',
  declined = 'declined',
  notReady = 'notready',
  underReview = 'review',
  completed = 'completed',
}
export enum EWorksheetStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
}
export enum PaymentMethods {
  check = 'check',
  bankTransfer = 'bankTransfer',
  DEFT = 'DEFT',
  WIRE = 'Wire',
}
export enum EPaymentPlan {
  regularDailyInterests = 'regularInterest',
}
export enum MerchantIds {
  IfinanceMerchantId = 'alis*20500',
  MedicardMerchantId = 'alis*20878',
}

export enum DocTypes {
  signedCvt = 47,
  LastPayStubJob = 17,
  SixMonthsPersonalBankStatements = 36,
  VoidCheque = 44,
  FamilyAllocationsProof = 68,
  WorkIncidentIndemnisationProof = 69,
  MaternityPaternityIncomeProof = 70,
  OtherIncomeProof = 71,
  RetirementIncomeProof = 72,
  DisabilityIncomeProof = 73,
  AssistanceProof = 74,
  EmployementInsuranceProof = 75,
  ValidPhotoId = 67,
}
export enum Province {
  notSelected = '',
  quebec = 'QC',
  ontario = 'ON',
  novaScotia = 'NS',
  newBrunswick = 'NB',
  princeEdwardIsland = 'PE',
  newfoundland = 'NL',
  britishColombia = 'BC',
  saskatchewan = 'SK',
  alberta = 'AB',
  manitoba = 'MB',
  yukon = 'YT',
  nunavut = 'NU',
  northwestTerritories = 'NT',
}
export enum EsupportedPaymentFrequency {
  monthly = 'Monthly',
  biweekly = 'biWeekly',
}
export const HiddenMerchantCategories = [
  Enum.ServiceCategory.Dentistry,
  Enum.ServiceCategory.Medical,
  Enum.ServiceCategory.Aesthetics,
]
