import { Box, Button, ListItemIcon, Typography } from '@mui/material'
import { Brightness7 as Brightness7Icon, Brightness4 as Brightness4Icon } from '@mui/icons-material'
import LanguagePicker from './LanguagePicker'
import AppMenu from './AppMenu'
import { useTheme } from '@src/contexts/ThemeContext'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function AppHeader(): JSX.Element {
  const { toggleTheme, themeName } = useTheme()
  const { t } = useTranslation()

  return (
    <header
      style={{
        backgroundColor: '#14233A',
        color: 'white',
      }}
    >
      <Box
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <div
          className="wrap"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            alignItems: 'start',
            gap: '1rem',
            padding: '1rem',
            overflow: 'hidden',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Button className="iFinanceBanner" component={Link} to="/">
              <img src="/images/logo.png" alt="iFinance" style={{ width: '110px', height: '25px' }} />
            </Button>
            <Typography variant="body2" sx={{ alignContent: 'flex-end', m: '0 0 3px 5px' }}>
              {t('common.contactUs')}
            </Typography>
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <AppMenu />
            <ListItemIcon onClick={() => toggleTheme()} style={{ justifyContent: 'center' }}>
              {themeName === 'dark' ? (
                <Brightness7Icon sx={{ color: '#ffffff' }} />
              ) : (
                <Brightness4Icon sx={{ color: '#ffffff' }} />
              )}
            </ListItemIcon>
            <LanguagePicker />
          </div>
        </div>
      </Box>
    </header>
  )
}
