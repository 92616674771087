import { DocTypes } from '@src/types/Constants'
import { ApplicantDto } from '@src/types/CreditApplicationSchema'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { useMemo } from 'react'

export const useHasRequiredDocuments = (
  requiredDocuments: RequiredDocument[],
  documentTypes: (number | string)[],
  applicantType: string,
  status: string,
): boolean => {
  const hasRequiredIncomeDocuments: boolean = useMemo(
    () =>
      requiredDocuments.some(
        (d) => documentTypes.includes(d.typeId) && d.status === status && d.applicantType === applicantType,
      ),
    [applicantType, documentTypes, requiredDocuments, status],
  )

  return hasRequiredIncomeDocuments
}

export const useHasBankStatementOrVoidCheque = (
  requiredDocuments: RequiredDocument[],
  applicantType: string,
): boolean => {
  const hasBankStatementOrVoidCheque: boolean = useMemo(
    () =>
      requiredDocuments.some(
        (d) =>
          (d.typeId === DocTypes.VoidCheque || d.typeId === DocTypes.SixMonthsPersonalBankStatements) &&
          d.applicantType === applicantType,
      ),
    [applicantType, requiredDocuments],
  )

  return hasBankStatementOrVoidCheque
}

export const getVerifiedIncome = (
  hasBankStatementOrVoidCheque: boolean,
  hasIncomeDocumentsApproved: boolean,
  applicant: ApplicantDto,
): number => {
  const verifiedIncome = () => {
    if (hasBankStatementOrVoidCheque && applicant.incomeVerification.incomeMismatch && !hasIncomeDocumentsApproved) {
      return applicant.incomeVerification.thirdPartyIncome
    } else {
      return applicant.incomeVerification.declaredIncome
    }
  }

  return verifiedIncome()
}

export const useCanContinueWithComputedIncome = (applicant: ApplicantDto) => {
  const canContinueWithComputedIncome: boolean = useMemo(
    () =>
      applicant.incomeVerification.thirdPartyIncome >= 21000 &&
      applicant.incomeVerification.thirdPartyIncome / applicant.incomeVerification.declaredIncome >= 0.5 &&
      !applicant.incomeVerification.nameMismatch,
    [applicant],
  )

  return canContinueWithComputedIncome
}
