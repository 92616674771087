import { PublicClientApplication, EventType, InteractionType, RedirectRequest } from '@azure/msal-browser'
import { msalConfig } from '../authConfig'
import { JwtToken, signIn } from '../data/api/user-api/user-api'
import { UserDtoFromJwt } from '../types/User'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

export function initializeMsal(): PublicClientApplication {
  // Listen for sign-in event and set active account
  msalInstance.addEventCallback(async (event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.interactionType === InteractionType.Redirect)
    ) {
      const { account } = event.payload as RedirectRequest
      if (account) {
        msalInstance.setActiveAccount(account)

        const responseToken = await msalInstance.acquireTokenSilent({
          account,
          scopes: [],
        })

        const token: JwtToken = { jwt: responseToken.idToken }
        const responseIceberg = await signIn(token)

        saveUserToken(responseIceberg.jwt)
        window.location.reload()
      }
    }
  })

  return msalInstance
}

// eslint-disable-next-line no-var
var JWT_LOCAL_STORAGE_KEY = 'merchantToken'

export function saveUserToken(jwtToken: string): void {
  localStorage.setItem(JWT_LOCAL_STORAGE_KEY, jwtToken)
}

export function getMerchantAuthToken() {
  let token = localStorage.getItem(JWT_LOCAL_STORAGE_KEY) ?? ''
  const user = token ? loadUserFromJwtString(token) : null
  if (!user) token = ''
  return token
}

export function getUser(): UserDtoFromJwt | null {
  const token = localStorage.getItem(JWT_LOCAL_STORAGE_KEY)
  return token ? loadUserFromJwtString(token) : null
}

function loadUserFromJwtString(jwtToken: string): UserDtoFromJwt | null {
  const dto = parseJwt(jwtToken) as UserDtoFromJwt
  return isExpiredToken(dto.exp) ? null : dto
}

function isExpiredToken(expiryDate: number): boolean {
  const date = new Date().valueOf() / 1000
  return date > expiryDate
}

function parseJwt(token: string): object {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )

  const payload = JSON.parse(jsonPayload)

  if (payload.isBannerUser !== undefined) {
    payload.isBannerUser = payload.isBannerUser === 'True'
  }

  return payload
}
