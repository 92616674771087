import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material'
import { t } from 'i18next'
import React from 'react'
import AsyncActionButton from './AsyncActionButton'

const CreditConsentDialog = ({
  openConsentDialog,
  setOpenConsentDialog,
  consentChecked,
  handleCheckboxChange,
  handleConfirm,
  dialogContentText,
  buttonText,
}) => {
  return (
    <Dialog open={openConsentDialog} onClose={() => setOpenConsentDialog(false)}>
      <DialogTitle>{t('submission.submission')}</DialogTitle>
      <DialogContent>
        <DialogContentText component={'span'}>
          {dialogContentText}
          <FormControlLabel
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
            control={
              <Checkbox id="consentCheckbox" checked={consentChecked} onChange={handleCheckboxChange} color="primary" />
            }
            label={t('submission.agreeToTerms')}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <AsyncActionButton
          fullWidth={false}
          id="cancelConsent"
          onClick={() => setOpenConsentDialog(false)}
          startIcon={<CancelIcon />}
        >
          {t('common.cancel')}
        </AsyncActionButton>
        <AsyncActionButton
          fullWidth={false}
          id="submitConsent"
          onClick={handleConfirm}
          variant="contained"
          disabled={!consentChecked}
          startIcon={<DoneIcon />}
        >
          {buttonText}
        </AsyncActionButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(CreditConsentDialog)
