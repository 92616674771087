import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import App from './containers/App/App'
import './index.css'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { MsalProvider } from '@azure/msal-react'
import { initializeAppInsights } from './services/AppInsights'
import { initializeMsal } from './services/Authentification'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { setupTracking } from './services/error-logger'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const ai = initializeAppInsights()
setupTracking(ai)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
const container = document.getElementById('root')
const root = createRoot(container)
const msalInstance = initializeMsal()

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'fr',
    returnNull: false,
    debug: false,
    supportedLngs: ['fr', 'en'],
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    void i18n.changeLanguage(i18n.language)
  })
  .then(() => {
    root.render(
      <Suspense fallback="loading">
        <I18nextProvider i18n={i18n}>
          <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
              <App />
              <div
                style={{
                  fontSize: '24px',
                }}
              >
                <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
              </div>
            </QueryClientProvider>
          </MsalProvider>
        </I18nextProvider>
      </Suspense>,
    )
  })
export default i18n
