import { ButtonProps } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { useDownloadTemplateContractPdf } from '@src/data/api/credit-api/files-api'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { t } from 'i18next'

interface Props extends ButtonProps {
  creditApp: CreditApplication
}

export default function SignManuallyButton({ creditApp }: Props) {
  const [downloadPdf, isDownloading, isError, isSuccess] = useDownloadTemplateContractPdf(
    creditApp.financingProgramId,
    creditApp.id,
  )

  return (
    <AsyncActionButton
      fullWidth
      variant="contained"
      isPending={isDownloading}
      isError={isError}
      isSuccess={isSuccess}
      onClick={() => downloadPdf(creditApp.contract.cvtNumber)}
    >
      {t('worksheet.signManually')}
    </AsyncActionButton>
  )
}
