import { Typography } from '@mui/material'
import { t } from 'i18next'
import FundingSummaryButton from './FundingSummaryButton'

type props = {
  id: string
  financingProgramId: string
}

export default function FundingLoanCompleted({ id, financingProgramId }: props) {
  return (
    <div>
      <Typography variant="body1">{t('viewCreditApplication.fundingCompleted')}</Typography>
      <br />
      <br />
      <FundingSummaryButton id={id} financingProgramId={financingProgramId} />
    </div>
  )
}
