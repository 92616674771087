import { t } from 'i18next'
import ResetBankAccountButton from './ResetBankAccountButton'

type props = {
  hasIdentityDocumentsPendingUpload: boolean
  hasIdentityDocumentsPendingReview: boolean
  allIncomesConfirmed: boolean
  applicantType: string
  creditApplicationId: string
  canEdit: boolean
}

export default function NameMismatch({
  hasIdentityDocumentsPendingUpload,
  hasIdentityDocumentsPendingReview,
  allIncomesConfirmed,
  applicantType,
  creditApplicationId,
  canEdit,
}: props) {
  const nameMismatchUnderReview =
    (hasIdentityDocumentsPendingReview || !allIncomesConfirmed) && !hasIdentityDocumentsPendingUpload

  return (
    <div>
      <p>{t('incomeVerification.nameMismatch.bankAccountNameMismatch')}</p>
      {nameMismatchUnderReview && (
        <>
          <p>{t('incomeVerification.startVerification.reviewing')}</p>
          <p>{t('incomeVerification.startVerification.notified')}</p>
        </>
      )}
      {hasIdentityDocumentsPendingUpload && (
        <>
          <p>{t('incomeVerification.nameMismatch.documents')}</p>
        </>
      )}
      <p>{t('incomeVerification.nameMismatch.reset')}</p>
      {canEdit && <ResetBankAccountButton applicantType={applicantType} id={creditApplicationId} />}
    </div>
  )
}
