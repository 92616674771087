import { Typography } from '@mui/material'
import { FormatCurrencyNoDecimals } from '@src/services/Formatter'
import { Enum } from '@src/types/Constants'
import { ApplicantDto } from '@src/types/CreditApplicationSchema'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { t } from 'i18next'
import { useState } from 'react'
import StepStatusSection from '../StepStatusSection'
import BankAccountProcedureDialog from './BankAccountProcedureDialog'
import IncomeVerificationMismatch from './IncomeVerificationMismatch'
import NameMismatch from './NameMismatch'
import { getVerifiedIncome, useHasBankStatementOrVoidCheque, useHasRequiredDocuments } from './incomeVerification-hooks'
import { getIdentityDocumentTypes, getIncomeVerificationDocumentsTypes } from '@src/data/income-selectors'
import { GetIconAndMessageForIncomeVerification } from '../../viewCreditApp-selectors'
import { Merchant } from '@src/types/Merchant'
import StartIncomeVerification from './StartIncomeVerification'

type props = {
  applicant: ApplicantDto
  requiredDocuments: RequiredDocument[]
  creditDecision: string
  applicationStatus: string
  creditApplicationId: string
  merchant: Merchant
  hasCoApplicant: boolean
  canEdit: boolean
}

export default function IncomeVerificationTile({
  applicant,
  requiredDocuments,
  creditDecision,
  applicationStatus,
  creditApplicationId,
  merchant,
  hasCoApplicant,
  canEdit,
}: props) {
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false)

  const applicantType = applicant.isPrimaryApplicant ? Enum.EApplicantType.Applicant : Enum.EApplicantType.Coapplicant
  const canStartStep = creditDecision === Enum.EDecision.accept
  const isDone = applicant.incomeVerification?.allIncomeTasksConfirmed
  const showVerifiedIncome =
    isDone || (applicant.incomeVerification?.incomeMismatch && !applicant.incomeVerification.nameMismatch)

  const hasIncomeServiceAdjustment = applicant.incomeVerification?.hasIncomeServiceAdjustment
  const hasBankStatementOrVoidCheque = useHasBankStatementOrVoidCheque(requiredDocuments, applicantType)

  const incomeVerificationDocumentsTypes = getIncomeVerificationDocumentsTypes
  const identityDocumentTypes = getIdentityDocumentTypes

  const hasIncomeDocumentsPendingUpload = useHasRequiredDocuments(
    requiredDocuments,
    Object.values(incomeVerificationDocumentsTypes),
    applicantType,
    Enum.DocumentStatus.AwaitingDocument,
  )
  const hasIncomeDocumentsPendingReview = useHasRequiredDocuments(
    requiredDocuments,
    Object.values(incomeVerificationDocumentsTypes),
    applicantType,
    Enum.DocumentStatus.AwaitingApproval,
  )
  const hasIncomeDocumentsApproved = useHasRequiredDocuments(
    requiredDocuments,
    Object.values(incomeVerificationDocumentsTypes),
    applicantType,
    Enum.DocumentStatus.Approved,
  )
  const hasIdentityDocumentPendingUpload = useHasRequiredDocuments(
    requiredDocuments,
    Object.values(identityDocumentTypes),
    applicantType,
    Enum.DocumentStatus.AwaitingDocument,
  )
  const hasIdentityDocumentPendingReview = useHasRequiredDocuments(
    requiredDocuments,
    Object.values(identityDocumentTypes),
    applicantType,
    Enum.DocumentStatus.AwaitingApproval,
  )

  const hasIdentityDocumentApproved = useHasRequiredDocuments(
    requiredDocuments,
    Object.values(identityDocumentTypes),
    applicantType,
    Enum.DocumentStatus.Approved,
  )

  const { iconMessage, iconType } = GetIconAndMessageForIncomeVerification(
    applicant,
    canStartStep,
    hasIncomeDocumentsPendingReview,
    hasIncomeDocumentsPendingUpload,
    hasIncomeDocumentsApproved,
    isDone,
    applicationStatus,
    hasIdentityDocumentPendingReview,
    hasIdentityDocumentApproved,
  )

  return (
    <StepStatusSection
      title={t('viewCreditApplication.incomeVerificationSectionTile')}
      subtitle={hasCoApplicant ? t(`common.${applicantType}`) : null}
      iconMessage={iconMessage ? t(`creditApplicationStatus.${iconMessage}`) : null}
      iconType={iconType}
      onFooterClicked={
        isDone || applicationStatus === Enum.ECreditApplicationStatus.Cancelled
          ? undefined
          : () => setOpenDetailsDialog(true)
      }
    >
      {!isDone && applicationStatus === Enum.ECreditApplicationStatus.Cancelled ? (
        <Typography variant="body1">{t('viewCreditApplication.creditAppCancelled')}</Typography>
      ) : (
        <>
          {/* Informative Dialog */}
          {openDetailsDialog && (
            <BankAccountProcedureDialog
              open={openDetailsDialog}
              onClose={() => setOpenDetailsDialog(false)}
              applicantType={applicantType}
              creditApplicationId={creditApplicationId}
              canSkipFlinks={merchant.canSkipFlinks}
            />
          )}

          {/* Content */}
          {canStartStep ? (
            <>
              {/* Income Verification Skipped */}
              {!applicant.incomeVerification.thirdPartyFlowCompleted && !isDone && (
                <StartIncomeVerification
                  applicant={applicant}
                  hasIncomeDocumentsPendingUpload={hasIncomeDocumentsPendingUpload}
                  hasIncomeDocumentsPendingReview={hasIncomeDocumentsPendingReview}
                  hasIncomeDocumentsApproved={hasIncomeDocumentsApproved}
                  creditApplicationId={creditApplicationId}
                  canSkipFlinks={merchant.canSkipFlinks}
                  canEdit={canEdit}
                />
              )}
              {/* Flinks is processing */}
              {applicant.incomeVerification.thirdPartyFlowCompleted && !hasBankStatementOrVoidCheque && (
                <p>{t('incomeVerification.message.waitingForFlinks')}</p>
              )}
              {/* Name Mismatch */}
              {applicant.incomeVerification.nameMismatch && (
                <NameMismatch
                  hasIdentityDocumentsPendingReview={hasIdentityDocumentPendingReview}
                  hasIdentityDocumentsPendingUpload={hasIdentityDocumentPendingUpload}
                  allIncomesConfirmed={isDone}
                  applicantType={applicantType}
                  creditApplicationId={creditApplicationId}
                  canEdit={canEdit}
                />
              )}
              {/* Income Mismatch */}
              {applicant.incomeVerification?.incomeMismatch &&
                !applicant.incomeVerification.nameMismatch &&
                !isDone && (
                  <IncomeVerificationMismatch
                    applicant={applicant}
                    hasIncomeDocumentsPendingReview={hasIncomeDocumentsPendingReview}
                    hasIncomeDocumentsPendingUpload={hasIncomeDocumentsPendingUpload}
                    hasIncomeServiceAdjustment={hasIncomeServiceAdjustment}
                    creditApplicationId={creditApplicationId}
                    canEdit={canEdit}
                  />
                )}
              {/* Verified Income */}
              {showVerifiedIncome && (
                <div>
                  <Typography variant="subtitle1">{t('incomeVerification.verifiedIncome')}</Typography>
                  <Typography variant="body1">
                    {FormatCurrencyNoDecimals(
                      getVerifiedIncome(hasBankStatementOrVoidCheque, hasIncomeDocumentsApproved, applicant),
                    )}
                  </Typography>
                </div>
              )}
              {/* Submitted Income */}
              <div>
                <Typography variant="subtitle1">{t('incomeVerification.totalIncome')}</Typography>
                <Typography variant="body1">
                  {FormatCurrencyNoDecimals(applicant.incomeVerification.declaredIncome)}
                </Typography>
              </div>
            </>
          ) : (
            <>
              <Typography variant="body1">{t('incomeVerification.notStarted')}</Typography>
            </>
          )}
        </>
      )}
    </StepStatusSection>
  )
}
